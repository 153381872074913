import { useState, useEffect } from "react";
import Modal from "react-modal";
import { FaSearch, FaEye } from "react-icons/fa";
import BASE_URL from "../constant";
import { toast } from "react-toastify";
import axios from "axios";
import { t } from "i18next";


const UserDetailsPopup = ({ selectedUser, closeModal,handleSave }) => {
  if (!selectedUser) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md sm:max-w-lg md:max-w-xl max-h-[85vh] overflow-y-auto border border-gray-300">
        <h2 className="text-2xl font-bold mb-4 text-gray-800 text-center border-b pb-2">
          Distributor Details
        </h2>

        {/* Personal Details Section */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-700 mb-3 border-b pb-1">
            Personal Details
          </h3>
          <div className="grid grid-cols-2 gap-4 text-sm text-gray-700">
            {[
              { label: "ID", value: selectedUser.id },
              { label: "FB ID", value: selectedUser.fb_id || "N/A" },
              { label: "Username", value: selectedUser.username },
              { label: "Email", value: selectedUser.email },
              { label: "Created At", value: selectedUser.created_at },
              { label: "Contact", value: selectedUser.contact || "N/A" },
              { label: "BillingAddress", value: selectedUser.address || "N/A" },

              {
                label: "Shipping Address",
                value: selectedUser.shippingAddress || "N/A",
              },
            ].map(({ label, value }, index) => (
              <div key={index} className="border rounded-lg p-2">
                <span className="text-xs font-semibold text-gray-600 bg-gray-100 px-2 py-1 rounded">
                  {label}
                </span>
                <p className="mt-1 text-gray-900">{value}</p>
              </div>
            ))}

            {/* Verified Status */}
            <div className="border rounded-lg p-2">
              <span className="text-xs font-semibold text-gray-600 bg-gray-100 px-2 py-1 rounded">
                Status
              </span>
              <p
                className={`mt-1 px-2 py-1 rounded-lg text-xs border w-fit ${
                  selectedUser.verified === "Approved"
                    ? "bg-green-100 text-green-700 border-green-500"
                    : "bg-red-100 text-red-700 border-red-500"
                }`}
              >
                {selectedUser.verified.toString().toUpperCase()}
              </p>
            </div>
          </div>
        </div>

        {/* Organization Details Section */}
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-3 border-b pb-1">
            Organization Details
          </h3>
          <div className="grid grid-cols-2 gap-4 text-sm text-gray-700">
            {[
              {
                label: "Org. Name",
                value: selectedUser.orgnizationName || "N/A",
              },
              {
                label: "Org. Address",
                value: selectedUser.orgnizationAddress || "N/A",
              },
              { label: "Tax ID", value: selectedUser.TaxID || "N/A" },
              {
                label: "Org. Phone",
                value: selectedUser.orgnizationPhone || "N/A",
              },
              {
                label: "Org. Email",
                value: selectedUser.orgnizationEmail || "N/A",
              },
              { label: "User Type", value: selectedUser.user_type || "N/A" },
            ].map(({ label, value }, index) => (
              <div key={index} className="border rounded-lg p-2">
                <span className="text-xs font-semibold text-gray-600 bg-gray-100 px-2 py-1 rounded">
                  {label}
                </span>
                <p className="mt-1 text-gray-900">{value}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Close Button */}
        <div className="mt-6 flex justify-center p-2 flex items-center  gap-2">
          <button
            onClick={closeModal}
            className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-lg shadow-md transition-all border border-red-700"
          >
            Close
          </button>
          <button
            title="Change Status"
            onClick={(e) =>  handleSave(e,selectedUser.verified === "Approved"?"pending":"Approved",selectedUser.id,selectedUser)}
            className={`text-white px-3 py-1 rounded flex items-center gap-2 ${
              selectedUser.verified === "Approved"
                ? "bg-red-300 text-red-700 border border-gray-500"
                : "bg-green-300 text-green-700 border border-gray-500"
            }`}
          >
            Change Status:{" "}
            {selectedUser.verified === "Approved" ? "Disapproved" : "Approved"}
          </button>
        </div>
      </div>
    </div>
  );
};

const UsersList = () => {

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
 

  useEffect(() => {
    Getusers()
  }, []);
  const Getusers=()=>{
    fetch(`${BASE_URL}/api_users.php?type=distributer`) // Replace with your API URL
    .then((res) => res.json())
    .then((data) => {
      setUsers(data);
      setFilteredUsers(data);
    })
    .catch((err) => console.error("Error fetching users:", err));
  };
  const handleSave = async (event,  status,userId,user) => {
    event.preventDefault();
    console.log({
    verified:status,
    action: "update",
    id: userId, // Ensure API gets the correct user ID
  }); console.log({name:user.username,email:user.email});
  
    // const newStatus = status === "Approved" ? "Disapproved" : "Approved"; // Toggle status
  
    try {
      const response = await axios.post(`${BASE_URL}/api_users.php`,  {
        verified:status,
        action: "update",
        id: userId, // Ensure API gets the correct user ID
      });
  
      console.log(response.data);
      if(status=="Approved"){
        console.log({name:user.username,email:user.username});
        sendWelcomeEmail({name:user.username,email:user.email});
      };
      Getusers(); // Refresh user list
      toast.success("User Updated Successfully");
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("An error occurred while updating the profile. Please try again.");
    }


  };
  

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    const filtered = users.filter(
      (user) =>
        user.username.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
    setCurrentPage(1);
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedUser(null);
  };
 const sendWelcomeEmail = async (data) => {
    const emaildata = JSON.stringify({
      email: data.email,
      name: data.name,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://oizvehadar.com/mailjs/send-org-account-approval-hebrew",
      headers: {
        "Content-Type": "application/json",
      },
      data: emaildata,
    };

    try {
      const response = await axios.request(config);
      console.log(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };
  // Pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">{t("distributorsList")}</h1>
  
      {/* Search Input */}
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder={t("searchUsers")}
          value={search}
          onChange={handleSearch}
          className="border p-2 rounded-lg w-80"
        />
        <FaSearch className="ml-2 text-gray-500" />
      </div>
  
      {/* Users Table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border p-2">ID</th>
              <th className="border p-2">{t("username")}</th>
              <th className="border p-2">{t("email")}</th>
              <th className="border p-2">{t("status")}</th>
              <th className="border p-2">{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.length > 0 ? (
              currentUsers.map((user) => (
                <tr key={user.id} className="hover:bg-gray-100">
                  <td className="border p-2">{user.id}</td>
                  <td className="border p-2">{user.username}</td>
                  <td className="border p-2">{user.email}</td>
                  <td
                    className={`border p-2 ${
                      user.verified === "Approved"
                        ? "bg-green-100 text-green-700 border-gray-500"
                        : "bg-red-100 text-red-700 border-gray-500"
                    }`}
                  >
                    {user.verified === "Approved" ? t("disapprove") : t("approve")}
                  </td>
  
                  <td className="border p-2 flex items-center justify-center gap-2">
                    {/* View Button */}
                    <button
                      onClick={() => openModal(user)}
                      className="bg-blue-500 text-white px-3 py-1 rounded flex items-center gap-2"
                    >
                      <FaEye /> {t("view")}
                    </button>
  
                    {/* Change Status Button */}
                    <button
                      title={t("changeStatus")}
                      onClick={(e) =>
                        handleSave(e, user.verified === "Approved" ? "pending" : "Approved", user.id, user)
                      }
                      className={`text-white px-3 py-1 rounded flex items-center gap-2 ${
                        user.verified === "Approved"
                          ? "bg-red-300 text-red-700 border border-gray-500"
                          : "bg-green-300 text-green-700 border border-gray-500"
                      }`}
                    >
                      {t("changeStatus")}:{" "}
                      {user.verified === "Approved" ? t("disapprove") : t("approve")}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center p-4">
                  {t("noUsersFound")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
  
      {/* Pagination */}
      {filteredUsers.length > 10 && (
        <div className="mt-4 flex justify-center space-x-2">
          {Array.from(
            { length: Math.ceil(filteredUsers.length / usersPerPage) },
            (_, i) => (
              <button
                key={i}
                onClick={() => setCurrentPage(i + 1)}
                className={`px-3 py-1 rounded-lg border ${
                  currentPage === i + 1 ? "bg-blue-500 text-white" : "bg-gray-100"
                }`}
              >
                {i + 1}
              </button>
            )
          )}
        </div>
      )}
  
      {modalIsOpen && (
        <UserDetailsPopup selectedUser={selectedUser} closeModal={closeModal} handleSave={handleSave} />
      )}
    </div>
  );
  
  // return (
  //   <div className="container mx-auto p-4">
  //     <h1 className="text-xl font-bold mb-4">Distributers List</h1>

  //     {/* Search Input */}
  //     <div className="flex items-center mb-4">
  //       <input
  //         type="text"
  //         placeholder="Search users..."
  //         value={search}
  //         onChange={handleSearch}
  //         className="border p-2 rounded-lg w-80"
  //       />
  //       <FaSearch className="ml-2 text-gray-500" />
  //     </div>

  //     {/* Users Table */}
  //     <div className="overflow-x-auto">
  //       <table className="w-full border-collapse border border-gray-300">
  //         <thead>
  //           <tr className="bg-gray-200">
  //             <th className="border p-2">ID</th>
  //             <th className="border p-2">Username</th>
  //             <th className="border p-2">Email</th>
  //             <th className="border p-2">Status</th>
  //             <th className="border p-2">Actions</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {currentUsers.length > 0 ? (
  //             currentUsers.map((user) => (
  //               <tr key={user.id} className="hover:bg-gray-100">
  //                 <td className="border p-2">{user.id}</td>
  //                 <td className="border p-2">{user.username}</td>
  //                 <td className="border p-2">{user.email}</td>
  //                 <td
  //                   className={`border p-2 ${
  //                     user.verified === "Approved"
  //                       ? "bg-green-100 text-green-700 border-gray-500"
  //                       : "bg-red-100 text-red-700 border-gray-500"
  //                   }`}
  //                 >
  //                   {user.verified?.toUpperCase()}
  //                 </td>

  //                 <td className="border p-2 flex items-center justify-center gap-2">
  //                   {/* View Button */}
  //                   <button
  //                     onClick={() => openModal(user)}
  //                     className="bg-blue-500 text-white px-3 py-1 rounded flex items-center gap-2"
  //                   >
  //                     <FaEye /> View
  //                   </button>

  //                   {/* Change Status Button */}
  //                   <button
  //                     title="Change Status"
  //                      onClick={(e) =>  handleSave(e,user.verified === "Approved"?"pending":"Approved",user.id,user)}
  //                     className={`text-white px-3 py-1 rounded flex items-center gap-2 ${
  //                       user.verified === "Approved"
  //                         ? "bg-red-300 text-red-700 border border-gray-500"
  //                         : "bg-green-300 text-green-700 border border-gray-500"
  //                     }`}
  //                   >
  //                     Change Status:{" "}
  //                     {user.verified === "Approved"
  //                       ? "Disapproved"
  //                       : "Approved"}
  //                   </button>
  //                 </td>
  //               </tr>
  //             ))
  //           ) : (
  //             <tr>
  //               <td colSpan="5" className="text-center p-4">
  //                 No users found.
  //               </td>
  //             </tr>
  //           )}
  //         </tbody>
  //       </table>
  //     </div>
  //     {filteredUsers.length > 10 && (
  //       <div className="mt-4 flex justify-center space-x-2">
  //         {Array.from(
  //           { length: Math.ceil(filteredUsers.length / usersPerPage) },
  //           (_, i) => (
  //             <button
  //               key={i}
  //               onClick={() => setCurrentPage(i + 1)}
  //               className={`px-3 py-1 rounded-lg border ${
  //                 currentPage === i + 1
  //                   ? "bg-blue-500 text-white"
  //                   : "bg-gray-100"
  //               }`}
  //             >
  //               {i + 1}
  //             </button>
  //           )
  //         )}
  //       </div>
  //     )}
  //     {/* Pagination */}

  //     {modalIsOpen && (
  //       <UserDetailsPopup selectedUser={selectedUser} closeModal={closeModal} handleSave={handleSave} />
  //     )}
  //   </div>
  // );
};

export default UsersList;
