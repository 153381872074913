import React, { useState } from "react";
import AddBook from "./AddBook";
import Addcat from "./AddCategory";
import Library from "./LibrayData";
import Dashboard from "./Dashboard";
import HomeBookList from "./HomeListBooks";
import AddBundle from "./AddBundle";
import AddSubscription from "./AddSubs";
import AddDonation from "./AddDonations";
import AddVol from "./AddVol";
import Addindex from "./AddIndex";
import UploadPDF from "./AddPDF";
import Order from "./OrderView";
import ActiveOrder from "./ActiveOrders";
import Catalog from "./DownloadCata";
import ContactUsList from "./ContactUsList";
import { t } from "i18next";
import ManualOrder from "../Admin/OfflineOrders";
const AdminSub = ({ isLoggedIn, handleLogout }) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <>
      <div className="w-[100%]  flex m-auto" dir="ltr">
        <div
          style={{
            background:
              "background: linear-gradient(180deg, #F2F9FF 0%, rgba(255, 255, 255, 0.00) 100%);",
            borderRadius: "25px", // to make the tabs pill-shaped
          }}
          className="w-[20%]"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              onClick={() => handleTabClick(1)}
              style={{
                padding: "0px",
                cursor: "pointer",
                borderBottomRightRadius: "25px", // adjust border radius
                borderTopRightRadius: "25px", // adjust border radius
                background: activeTab === 1 ? "#fde5c3c4" : "transparent",
                boxShadow:
                  activeTab === 1
                    ? "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px"
                    : "",
              }}
            >
              <p className="text-left  justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-l font-lato font-normal">
                {t("dashboard")}
              </p>
            </div>

            <div
              onClick={() => handleTabClick(2)}
              style={{
                padding: "0px",
                cursor: "pointer",
                borderBottomRightRadius: "25px", // adjust border radius
                borderTopRightRadius: "25px", // adjust border radius
                background: activeTab === 2 ? "#fde5c3c4" : "transparent",
                boxShadow:
                  activeTab === 2
                    ? "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px"
                    : "",
              }}
            >
              <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
                {t("edit_book")}
              </p>
            </div>
            <div
              onClick={() => handleTabClick(3)}
              style={{
                padding: "0px",
                cursor: "pointer",
                borderBottomRightRadius: "25px", // adjust border radius
                borderTopRightRadius: "25px", // adjust border radius
                background: activeTab === 3 ? "#fde5c3c4" : "transparent",
                boxShadow:
                  activeTab === 3
                    ? "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px"
                    : "",
              }}
            >
              <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
                {t("edit_vol")}
              </p>
            </div>

            <div
              onClick={() => handleTabClick(5)}
              style={{
                padding: "0px",
                cursor: "pointer",
                borderBottomRightRadius: "25px", // adjust border radius
                borderTopRightRadius: "25px", // adjust border radius
                background: activeTab === 5 ? "#fde5c3c4" : "transparent",
                boxShadow:
                  activeTab === 5
                    ? "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px"
                    : "",
              }}
            >
              <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
                {t("orders")}
              </p>
            </div>
            <div
              onClick={() => handleTabClick(7)}
              style={{
                padding: "0px",
                cursor: "pointer",
                borderBottomRightRadius: "25px", // adjust border radius
                borderTopRightRadius: "25px", // adjust border radius
                background: activeTab === 7 ? "#fde5c3c4" : "transparent",
                boxShadow:
                  activeTab === 7
                    ? "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px"
                    : "",
              }}
            >
              <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
                {t("offline_orders")}
              </p>
            </div>
            <div
            onClick={() => {handleTabClick(6);
              // localStorage.setItem("language", "en");
            }}
              style={{
                padding: "0px",
                cursor: "pointer",
                borderBottomRightRadius: "25px", // adjust border radius
                borderTopRightRadius: "25px", // adjust border radius
                background: activeTab === 18 ? "#fde5c3c4" : "transparent",
                boxShadow:
                  activeTab === 18
                    ? "rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 2px"
                    : "",
              }}
            >
              <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal">
                {t("logout")}
              </p>
            </div>
          </div>
        </div>

        <div className="profile-right w-[75%] mt-6 m-auto">
          {activeTab === 1 && (
            <>
              <Dashboard />
            </>
          )}
          {activeTab === 2 && (
            <>
              <AddBook />
            </>
          )}

          {activeTab === 3 && (
            <>
              <AddVol />
            </>
          )}

          {activeTab === 5 && (
            <>
              <ActiveOrder />
            </>
          )}
          {activeTab === 7 && (
            <>
              <ManualOrder
                isAdmin={false}
                currency={
                  localStorage.getItem("type") === "israel-warehouse"
                    ? "ils"
                    : "usd"
                }
              />
            </>
          )}

          {activeTab === 6 && handleLogout(false)}
        </div>
      </div>
    </>
  );
};

export default AdminSub;
