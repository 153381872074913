import React, { useState } from "react";
import axios from "axios";
import BASE_URL from "../constant";
import { Markup } from "interweave";
import Select from "react-select";
import htmlToPlainText from "../htmltotext";
import { t } from "i18next";
// Select

const NewArrivalApp = () => {
  const [newArrivals, setNewArrivals] = useState([]);

  // Handle adding new arrival
  const handleAddNewArrival = async () => {
    if (!selectedfiles.value) {
      alert("Please select a book .");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api_book.php/newArrival`, {
        book_id: selectedfiles.value,
      });

      if (response.data.status === "success") {
        alert("New arrival added successfully.");
        // setBookId(""); // Clear input after successful submission
        fetchNewArrivals(); // Refresh the list of new arrivals
        setIsModalOpen(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Error adding new arrival.");
    }
  };

  // Handle deleting new arrival
  const handleDeleteNewArrival = async (id) => {
    if (!id) {
      alert("Please provide the arrival ID to delete.");
      return;
    }

    try {
      const response = await axios.delete(
        `${BASE_URL}/api_book.php/newArrival`,
        {
          data: { id },
        }
      );

      if (response.data.status === "success") {
        alert("New arrival deleted successfully.");
        fetchNewArrivals(); // Refresh the list of new arrivals
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert("Error deleting new arrival.");
    }
  };

  // Fetch new arrivals
  const fetchNewArrivals = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_book.php/newArrival`);
      if (response.data.status === "success") {
        setNewArrivals(response.data.data);
      } else {
        alert("No new arrivals found.");
      }
    } catch (error) {
      alert("Error fetching new arrivals.");
    }
  };
  const [books, setBooks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch list of books
  const fetchBooks = async () => {
    try {
      let url = `${BASE_URL}/api_book.php/books`;

      const response = await axios.get(url);
      const bookData = response.data;
      //   console.log(response.data);
      if (Array.isArray(bookData)) {
        const formattedBooks = bookData.map((book) => ({
          label: htmlToPlainText(book.title), // Display title
          value: book.id, // Use book ID as the value
        }));
        console.log(formattedBooks);
        setBooks(formattedBooks);
        // setBooks(bookData);
        // sliderData1(bookData);
        // extractTopics(bookData);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };
  // Fetch new arrivals on page load
  React.useEffect(() => {
    fetchNewArrivals();
    fetchBooks();
  }, []);
  const [selectedfiles, setSelectedFiles] = useState({});
  const ProductCard = ({
    id,
    image,
    title,
    price,
    symbol,
    handleDeleteNewArrival,
  }) => {
    return (
      <div className="group flex flex-col items-center w-full gap-4 p-5 sm:p-6 border border-gray-300 shadow-md rounded-lg hover:shadow-lg transition-transform duration-300 transform hover:scale-105 bg-white">
        {/* Product Image */}
        <div>
          {image ? (
            <img
              src={image}
              alt={title || "Product Image"}
              loading="lazy"
              className="w-full h-[210px] object-contain rounded-lg hover:opacity-90 transition-opacity duration-300"
            />
          ) : (
            <div className="flex items-center justify-center w-full h-[210px] bg-gray-100 text-gray-500 rounded-lg">
              <svg
                fill="#000000"
                width="800px"
                height="800px"
                viewBox="0 0 32 32"
                className="mr-[10px] ml-[10px] w-[90%] h-[210px] ml-7 mt-1.5 object-contain"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M30,3.4141,28.5859,2,2,28.5859,3.4141,30l2-2H26a2.0027,2.0027,0,0,0,2-2V5.4141ZM26,26H7.4141l7.7929-7.793,2.3788,2.3787a2,2,0,0,0,2.8284,0L22,19l4,3.9973Zm0-5.8318-2.5858-2.5859a2,2,0,0,0-2.8284,0L19,19.1682l-2.377-2.3771L26,7.4141Z" />
                <path d="M6,22V19l5-4.9966,1.3733,1.3733,1.4159-1.416-1.375-1.375a2,2,0,0,0-2.8284,0L6,16.1716V6H22V4H6A2.002,2.002,0,0,0,4,6V22Z" />
              </svg>
            </div>
          )}
        </div>
        {/* Product Info */}
        <div className="px-4 py-3 w-full">
          {/* Title */}
          <div
            className="group-hover:text-gray-600 transition-colors duration-300 overflow-hidden"
            style={{ height: "2.5em", lineHeight: "1.5em" }}
          >
            <Markup content={title} />
          </div>

          {/* Price & Discount */}
          <div className="flex items-center justify-between mt-1">
            <p className="text-lg font-semibold text-gray-900">
              <span className="text-sm text-gray-500">{symbol}</span> {price}
            </p>
          </div>

          <button
            onClick={() => handleDeleteNewArrival(id)} // Corrected function call
            className="bg-red-500 text-white px-2 py-1 rounded mt-2"
          >
            {t("delete")}
          </button>
        </div>
      </div>
    );
  };

  const handleBookChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedFiles(selectedOption ? selectedOption : null);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{t("new_arrivals")}</h1>
  
      {/* Add New Arrival Button */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-red-800 text-white px-4 py-2 rounded mb-6"
      >
        {t("add_new_arrival")}
      </button>
  
      {/* Modal for selecting a book */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <label className="block mb-2" htmlFor="books">
              {t("select_book")}
            </label>
            <Select
              id="books"
              name="books"
              options={books}
              value={books.find((book) => book.value === selectedfiles)}
              onChange={handleBookChange}
              placeholder={t("search")}
              className="w-full"
            />
  
            <div className="mt-4">
              <button
                onClick={handleAddNewArrival}
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
              >
                {t("add_new_arrival")}
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}
  
      <h2 className="text-xl mb-2">{t("new_arrivals_list")}</h2>
      <div className="grid grid-cols-5 gap-4">
        {newArrivals.length > 0 ? (
          newArrivals.map((product) => (
            <ProductCard
              id={product.id}
              image={product.images[0]}
              title={product.title}
              price={product.price}
              symbol={product.currency_symbol}
              handleDeleteNewArrival={handleDeleteNewArrival}
            />
          ))
        ) : (
          <p>{t("no_new_arrivals_found")}</p>
        )}
      </div>
    </div>
  );
  
};

export default NewArrivalApp;
