import { useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../constant";
import { BsTrash } from "react-icons/bs";
import { t } from "i18next";

const FAQComponent = () => {
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [savedLang, setSaveLang] = useState(localStorage.getItem("language") || "en");
  const [ques, setQues] = useState([]);
  const [ans, setAns] = useState([]);

  // New FAQ form states
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [newQuestionIs, setNewQuestionIs] = useState("");
  const [newAnswerIs, setNewAnswerIs] = useState("");

  // Popup form visibility state
  const [showPopup, setShowPopup] = useState(false);

  // Fetch FAQ data from the API
  useEffect(() => {
    fetchFaqData();
  }, []);

  const fetchFaqData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_faqs.php`);
      setFaqData(response.data);
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // Update questions and answers when language or faqData changes
  useEffect(() => {
    if (faqData.questions) {
      const language = localStorage.getItem("language") || "en";
      setSaveLang(language);
      setQues(language === "en" ? faqData.questions : faqData.questions_is);
      setAns(language === "en" ? faqData.answers : faqData.answers_is);
    }
  }, [faqData]);

  // Handle the form submission for new FAQ
  const handleNewFaqSubmit = async (e) => {
    e.preventDefault();
    if (!newQuestion || !newAnswer || !newQuestionIs || !newAnswerIs) {
      alert("Please provide all fields.");
      return;
    }

    try {
      // Send the new FAQ data to the API
      await axios.post(`${BASE_URL}/api_faqs.php`, {
        questions: [newQuestion],
        answers: [newAnswer],
        questions_is: [newQuestionIs],
        answers_is: [newAnswerIs],
      });

      // Refresh the FAQ data after adding
      setNewQuestion("");
      setNewAnswer("");
      setNewQuestionIs("");
      setNewAnswerIs("");
      fetchFaqData();
      setShowPopup(false); // Close the popup
    } catch (error) {
      console.error("Error adding FAQ:", error);
      alert("There was an error adding the FAQ.");
    }
  };

  // Delete FAQ with confirmation
  const handleDeleteFaq = async (faqId) => {
    // Confirm deletion
    const confirmDelete = window.confirm("Are you sure you want to delete this FAQ?");
    if (!confirmDelete) return;
  
    try {
      // Prepare the data to be sent in the request body
      const data = JSON.stringify({
        "id": faqId
      });
  
      // Perform the delete request using axios
      const response = await axios.delete(`${BASE_URL}/api_faqs.php`, {
        headers: { 
          'Content-Type': 'application/json'
        },
        data: data
      });
  
      // Handle the response (success)
      console.log('Delete Response:', response.data);
  
      // Refresh the FAQ data after successful deletion
      fetchFaqData();
  
    } catch (error) {
      // Handle error if delete fails
      console.error("Error deleting FAQ:", error);
      alert("There was an error deleting the FAQ.");
    }
  };
  

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error loading FAQ data!</div>;

 

  // return (
  //   <div className="faq-container max-h-[800px] overflow-y-auto p-4">
  //     {/* Button to open the popup form */}
  //     <button
  //       onClick={() => setShowPopup(true)}
  //       className="bg-red-800 text-white py-2 px-4 rounded mb-4"
  //     >
  //       Add New FAQ
  //     </button>

  //     {/* Popup Form to Add New FAQ */}
  //     {showPopup && (
  //       <div className="popup-overlay fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
  //         <div className="popup-form bg-white p-6 rounded-lg">
  //           <h2 className="text-xl mb-4">Add a New FAQ</h2>
  //           <form onSubmit={handleNewFaqSubmit}>
  //             <div className="mb-4">
  //               <label htmlFor="question" className="block font-semibold">Question</label>
  //               <input
  //                 type="text"
  //                 id="question"
  //                 value={newQuestion}
  //                 onChange={(e) => setNewQuestion(e.target.value)}
  //                 className="w-full p-2 border rounded"
  //                 required
  //               />
  //             </div>
  //             <div className="mb-4">
  //               <label htmlFor="answer" className="block font-semibold">Answer</label>
  //               <textarea
  //                 id="answer"
  //                 value={newAnswer}
  //                 onChange={(e) => setNewAnswer(e.target.value)}
  //                 className="w-full p-2 border rounded"
  //                 required
  //               />
  //             </div>
  //             <div className="mb-4">
  //               <label htmlFor="question_is" className="block font-semibold">Question (in Hebrew)</label>
  //               <input
  //                 type="text"
  //                 id="question_is"
  //                 value={newQuestionIs}
  //                 onChange={(e) => setNewQuestionIs(e.target.value)}
  //                 className="w-full p-2 border rounded"
  //                 required
  //               />
  //             </div>
  //             <div className="mb-4">
  //               <label htmlFor="answer_is" className="block font-semibold">Answer (in Hebrew)</label>
  //               <textarea
  //                 id="answer_is"
  //                 value={newAnswerIs}
  //                 onChange={(e) => setNewAnswerIs(e.target.value)}
  //                 className="w-full p-2 border rounded"
  //                 required
  //               />
  //             </div>
  //             <div className="flex justify-between">
  //               <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">Submit</button>
  //               <button
  //                 type="button"
  //                 onClick={() => setShowPopup(false)}
  //                 className="bg-red-500 text-white py-2 px-4 rounded"
  //               >
  //                 Cancel
  //               </button>
  //             </div>
  //           </form>
  //         </div>
  //       </div>
  //     )}

  //     {/* Display Existing FAQ */}
  //     <div className="faq-container p-2">
  //       <h2 className="text-xl font-semibold mb-4">FAQ List</h2>
  //       <table className="min-w-full table-auto border-collapse">
  //         <thead>
  //           <tr>
  //             <th className="border-b px-4 py-2 text-left">Question</th>
  //             <th className="border-b px-4 py-2 text-left">Answer</th>
  //             <th className="border-b px-4 py-2 text-left">Question (Hebrew)</th>
  //             <th className="border-b px-4 py-2 text-left">Answer (Hebrew)</th>
  //             <th className="border-b px-4 py-2 text-left">Actions</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {ques.map((question, index) => {
  //             const answer = ans[index];
  //             const questionIs = faqData.questions_is ? faqData.questions_is[index] : '';
  //             const answerIs = faqData.answers_is ? faqData.answers_is[index] : '';
  //             const faqId = faqData.id ? faqData.id[index] : null;

  //             return (
  //               <tr key={index} className="border-b">
  //                 <td className="px-4 py-2">{question}</td>
  //                 <td className="px-4 py-2">{answer}</td>
  //                 <td className="px-4 py-2">{questionIs}</td>
  //                 <td className="px-4 py-2">{answerIs}</td>
  //                 <td className="px-4 py-2 text-center">
  //                   <button
  //                     onClick={() => handleDeleteFaq(faqId)}
  //                     className="bg-red-500 text-white py-1 px-2 rounded"
  //                   >
  //                   <BsTrash />Delete
  //                   </button>
  //                 </td>
  //               </tr>
  //             );
  //           })}
  //         </tbody>
  //       </table>
  //     </div>
  //   </div>
  // );
  if (loading) return <div>{t("loading")}...</div>;
  if (error) return <div>{t("error_loading_faq")}</div>;

  return (
    <div className="faq-container max-h-[800px] overflow-y-auto p-4">
      {/* Button to open the popup form */}
      <button
        onClick={() => setShowPopup(true)}
        className="bg-red-800 text-white py-2 px-4 rounded mb-4"
      >
        {t("add_new_faq")}
      </button>

      {/* Popup Form to Add New FAQ */}
      {showPopup && (
        <div className="popup-overlay fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
          <div className="popup-form bg-white p-6 rounded-lg">
            <h2 className="text-xl mb-4">{t("add_new_faq")}</h2>
            <form onSubmit={handleNewFaqSubmit}>
              <div className="mb-4">
                <label htmlFor="question" className="block font-semibold">
                  {t("question")}
                </label>
                <input
                  type="text"
                  id="question"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="answer" className="block font-semibold">
                  {t("answer")}
                </label>
                <textarea
                  id="answer"
                  value={newAnswer}
                  onChange={(e) => setNewAnswer(e.target.value)}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="question_is" className="block font-semibold">
                  {t("question_hebrew")}
                </label>
                <input
                  type="text"
                  id="question_is"
                  value={newQuestionIs}
                  onChange={(e) => setNewQuestionIs(e.target.value)}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="answer_is" className="block font-semibold">
                  {t("answer_hebrew")}
                </label>
                <textarea
                  id="answer_is"
                  value={newAnswerIs}
                  onChange={(e) => setNewAnswerIs(e.target.value)}
                  className="w-full p-2 border rounded"
                  required
                />
              </div>
              <div className="flex justify-between">
                <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                  {t("submit")}
                </button>
                <button
                  type="button"
                  onClick={() => setShowPopup(false)}
                  className="bg-red-500 text-white py-2 px-4 rounded"
                >
                  {t("cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Display Existing FAQ */}
      <div className="faq-container p-2">
        <h2 className="text-xl font-semibold mb-4">{t("faq_list")}</h2>
        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr>
              <th className="border-b px-4 py-2 text-left">{t("question")}</th>
              <th className="border-b px-4 py-2 text-left">{t("answer")}</th>
              <th className="border-b px-4 py-2 text-left">{t("question_hebrew")}</th>
              <th className="border-b px-4 py-2 text-left">{t("answer_hebrew")}</th>
              <th className="border-b px-4 py-2 text-left">{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {ques.map((question, index) => {
              const answer = ans[index];
              const questionIs = faqData.questions_is ? faqData.questions_is[index] : "";
              const answerIs = faqData.answers_is ? faqData.answers_is[index] : "";
              const faqId = faqData.id ? faqData.id[index] : null;

              return (
                <tr key={index} className="border-b">
                  <td className="px-4 py-2">{question}</td>
                  <td className="px-4 py-2">{answer}</td>
                  <td className="px-4 py-2">{questionIs}</td>
                  <td className="px-4 py-2">{answerIs}</td>
                  <td className="px-4 py-2 text-center">
                    <button
                      onClick={() => handleDeleteFaq(faqId)}
                      className="bg-red-500 text-white py-1 px-2 rounded flex items-center"
                    >
                      <BsTrash className="mr-1" />
                      {t("delete")}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FAQComponent;
