import React, { useState, useEffect ,useRef} from "react";
import axios from "axios";
import BASE_URL from "../constant";
import htmlToPlainText from "../htmltotext";
import { t } from "i18next";
import { FaEdit } from "react-icons/fa";
import i18n from "../i18n";
import InvoiceTemplate from "../Admin/invoice-template";
import { useReactToPrint } from "react-to-print";

// const ActiveOrder = () => {
//   const [type, setType] = useState(localStorage.getItem("type") || "");
//   const [orders, setOrders] = useState([]);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [trackingId, setTrackingId] = useState("");
//   const [deliveryStatus, setDeliveryStatus] = useState("");
//   const delivery_status_enum = (stat) => {
//     switch (stat.toLowerCase()) {
//       case "pending":
//         return "Booked";
//       case "shipped":
//         return "Dispatched";
//       case "delivered":
//         return "Delivered";
//       case "cancelled":
//         return "Cancel";
//       default:
//         return "Booked";
//     }
//   };

//   // Admin statuses back to database statuses
//   const admin_status_enum = (adminStat) => {
//     console.log();
//     switch (adminStat.toLowerCase()) {
//       case "booked":
//         return "pending";
//       case "dispatched":
//         return "shipped";
//       case "delivered":
//         return "delivered";
//       case "cancel":
//         return "cancelled";
//       default:
//         return "pending"; // Default case
//     }
//   };
//   const deliveryStatuses = ["Booked", "Dispatched", "Delivered", "Cancel"];

//   const handleEdit = async (order) => {
//     await fetchOrderDetails(order.id);
//     setModalOpen(true);
//   };

//   useEffect(() => {
//     GetOrder();
//   }, []);

//   const fetchOrderDetails = async (orderId) => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/api_porders.php?action=get_order_details&order_id=${orderId}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       console.log("Order details fetched:", response.data);
//       setSelectedOrder({ ...response.data.order, id: orderId });
//       setDeliveryStatus(response.data.order.delivery_status);
//       setTrackingId(response.data.order.tracking_id);
//     } catch (error) {
//       console.error("Error fetching order details:", error);
//     }
//   };

//   const GetOrder = async () => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/api_porders.php?action=get_currency_orders&currency=${
//           type !== "israel-warehouse" ? "usd" : "ils"
//         }`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       console.log("Orders successfully fetched:", response.data);
//       setOrders(response.data.orders);
//     } catch (error) {
//       console.error("Error fetching orders:", error);
//     }
//   };

//   const handleStatusChange = (e) => {
//     console.log()
//     setDeliveryStatus(e.target.value);
//   };

//   const handleTrackingIdChange = (e) => {
//     setTrackingId(e.target.value);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//     setSelectedOrder(null);
//     setTrackingId(""); // Resetting state when modal closes
//     setDeliveryStatus(""); // Resetting state when modal closes
//   };

//   const handleSaveTrackingId = async () => {
//     try {
//       const updatedOrder = {
//         order_id: selectedOrder.id,
//         tracking_id: trackingId,
//         delivery_status: admin_status_enum(deliveryStatus),
//       };
//       console.log(updatedOrder);

//       // const promises = [];

//       if (selectedOrder.tracking_id !== trackingId) {
//         const response_tracking = axios.put(
//           `${BASE_URL}/api_porders.php?action=update_tracking_status`,
//           {
//             order_id: updatedOrder.order_id,
//             tracking_id: updatedOrder.tracking_id,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         console.log(response_tracking.data);
//       }

//       if (selectedOrder.delivery_status !== deliveryStatus) {
//         const response_status = await axios.put(
//           `${BASE_URL}/api_porders.php?action=update_tracking_status`,
//           {
//             order_id: updatedOrder.order_id,
//             delivery_status: updatedOrder.delivery_status,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
//           }
//         );
//         console.log(response_status.data);
//       }

//       // await Promise.all(promises);
//       console.log("Updates successful");

//       const updatedOrders = orders.map((order) =>
//         order.id === updatedOrder.order_id
//           ? {
//               ...order,
//               tracking_id: updatedOrder.tracking_id,
//               delivery_status: updatedOrder.delivery_status,
//             }
//           : order
//       );

//       setOrders(updatedOrders);
//       setModalOpen(false); // Close modal after saving
//     } catch (error) {
//       console.error("Error updating order:", error);
//     }
//   };

//   return (
//     <div className="p-6">
//       <h1 className="text-2xl font-bold mb-4">{t("orders")}</h1>
//       <table className="border-collapse w-full border border-gray-200 mt-4">
//         <thead className="bg-[#fde5c3c4] text-left">
//           <tr>
//             <th className="border border-gray-300 p-2">{t("order_id")}</th>
//             <th className="border border-gray-300 p-2">{t("customer_name")}</th>
//             <th className="border border-gray-300 p-2">{t("currency")}</th>
//             <th className="border border-gray-300 p-2">{t("total_price")}</th>
//             <th className="border border-gray-300 p-2">{t("status")}</th>
//             <th className="border border-gray-300 p-2">{t("track_id")}</th>
//             <th className="border border-gray-300 p-2">{t("action")}</th>
//           </tr>
//         </thead>
//         <tbody>
//           {orders.map((order) => (
//             <tr key={order.id}>
//               <td className="border border-gray-300 p-2">PHY-ORD-{order.id}</td>
//               <td className="border border-gray-300 p-2">{order.username}</td>
//               <td className="border border-gray-300 p-2">
//                 {t(order.currency)}
//               </td>
//               <td className="border border-gray-300 p-2">
//                 {order.currency !== "ils" ? "$" : "₪"} {order.final_amount}
//               </td>
//               <td className="border border-gray-300 p-2">
//                 {t(order.delivery_status)}
//               </td>
//               <td className="border border-gray-300 p-2">
//                 {order.tracking_id}
//               </td>
//               <td className="border border-gray-300 p-2">
//                 <button
//                   onClick={() => handleEdit(order)}
//                   className="bg-[#6D3815] w-full text-white flex justify-center items-center rounded px-2 py-2"
//                 >
//                   <FaEdit />
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {isModalOpen && selectedOrder && (
//         <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
//           <div className="bg-white p-6 rounded-lg shadow-lg w-[80%]">
//             <h2 className="text-xl font-bold mb-4">Edit Order</h2>
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//               <div>
//                 <label className="block mb-2">{t("order_id")}</label>
//                 <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
//                   PHY-ORD-{selectedOrder.id}
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">{t("customer_name")}</label>
//                 <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
//                   {selectedOrder.username}
//                 </div>
//               </div>

//               <div>
//                 <label className="block mb-2 px-2">{t("status")}</label>
//                 <select
//                   value={delivery_status_enum(deliveryStatus)}
//                   onChange={handleStatusChange}
//                   className="border-b-2 bg-gray-300 border-gray-300 rounded p-2 w-full"
//                 >
//                   {deliveryStatuses.map((status) => (
//                     <option key={status} value={status}>
//                       {status}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//               <div className="col-span-3 overflow-auto max-h-[300px]">
//                 <div className="col-span-3 overflow-auto max-h-[300px]">
//                   <div className="border-b-2 border-gray-300 p-2">
//                     <div className="flex justify-between py-2 border-b-2 border-gray-100 items-center">
//                       <span className="text-gray-500 text-center flex-1">
//                         {t("item")}
//                       </span>
//                       <span className="text-gray-500 text-center flex-1">
//                         {t("price")}
//                       </span>
//                       <span className="text-gray-500 text-center flex-1">
//                         {t("qty")}
//                       </span>
//                       <span className="text-gray-500 text-center flex-1">
//                         {t("total_price")}
//                       </span>
//                     </div>
//                     {selectedOrder.details?.map((item) => (
//                       <div
//                         className="flex justify-between py-2 border-b-2 border-gray-100 items-center"
//                         key={item.id}
//                       >
//                         <div className="flex flex-col items-center text-gray-500 flex-1">
//                           <span>{htmlToPlainText(item.title)}</span>
//                           {!item.paid && (
//                             <span className="text-sm text-gray-400">
//                               {item.SKU || `SKU-P-PAGE`}
//                             </span>
//                           )}
//                         </div>
//                         <span className="text-gray-500 text-center flex-1">
//                           {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
//                           {item.price}
//                         </span>
//                         <span className="text-gray-500 text-center flex-1">
//                           {item.quantity}
//                         </span>
//                         <span className="text-gray-500 text-center flex-1">
//                           {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
//                           {(item.price * item.quantity).toFixed(2)}
//                         </span>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="grid grid-cols-1 md:grid-cols-4 mt-6 gap-4">
//               <div>
//                 <label className="block mb-2">{t("track_id")}</label>
//                 <input
//                   type="text"
//                   value={trackingId}
//                   onChange={handleTrackingIdChange}
//                   className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2 w-full"
//                 />
//               </div>
//               <div>
//                 <label className="block mb-2">{t("trans_id")}</label>
//                 <div className="border-b-2 shadow text-gray-500 px-2 border-gray-300 p-2">
//                   {selectedOrder.transaction_id}
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">{t("delivery_address")}</label>
//                 <div className="border-b-2 shadow text-gray-500 px-2 border-gray-300 p-2">
//                   {selectedOrder.address}
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">{t("billing_address")}</label>
//                 <div className="border-b-2 text-gray-500 border-gray-300 p-2">
//                   {selectedOrder.address}
//                 </div>
//               </div>

//               <div>
//                 <label className="block mb-2">{t("currency")}</label>
//                 <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
//                   {selectedOrder.currency.toUpperCase()}
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">{t("payment_method")}</label>
//                 <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
//                   {selectedOrder.paymentMethod ?? "Card Payment"}
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">{t("order_date")}</label>
//                 <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
//                   {selectedOrder.orderDate}
//                 </div>
//               </div>
//               <div>
//                 <label className="block mb-2">{t("total_price")}</label>
//                 <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
//                   {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
//                   {selectedOrder.final_amount}
//                 </div>
//               </div>
//             </div>
//             <button
//               onClick={handleSaveTrackingId}
//               className="mt-4 bg-blue-500 text-white p-2 rounded"
//             >
//               {t("save")}
//             </button>
//             <button
//               onClick={closeModal}
//               className="mt-4 ml-2 bg-red-500 text-white p-2 rounded"
//             >
//               {t("close")}
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const ActiveOrder = () => {
  const [type, setType] = useState(localStorage.getItem("type") || "");
  const [activeTab, setActiveTab] = useState("all_orders");
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [trackingId, setTrackingId] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const delivery_status_enum = (stat) => {
    if (!stat) return "Booked"; // Handle null/undefined cases

    const statusMap = {
      pending: "Booked",
      shipped: "Dispatched",
      delivered: "Delivered",
      cancelled: "Cancel",
    };

    return statusMap[stat.toLowerCase()] || "Booked";
  };

  // Admin statuses back to database statuses
  const admin_status_enum = (adminStat) => {
    if (!adminStat) return "pending"; // Handle null/undefined cases

    const adminStatusMap = {
      booked: "pending",
      dispatched: "shipped",
      delivered: "delivered",
      cancel: "cancelled",
    };

    return adminStatusMap[adminStat.toLowerCase()] || "pending";
  };

  // Array of allowed statuses
  const deliveryStatuses = ["Booked", "Dispatched", "Delivered", "Cancel"];

  const handleEdit = async (order) => {
    setSelectedOrder(order);
    // await fetchOrderDetails(order.id);
    setModalOpen(true);
  };

  useEffect(() => {
    GetOrder();
  }, []);

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await axios.get(
        
        `${BASE_URL}/api_porders.php?action=get_order_details&order_id=${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Order details fetched:", response.data);
      setSelectedOrder({ ...response.data.order, id: orderId });
      setDeliveryStatus(response.data.order.delivery_status);
      setTrackingId(response.data.order.tracking_id);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const GetOrder = async (tab) => {
    try {
      console.log(tab);
      const response = await axios.get(
      
        tab == null
          ? `${BASE_URL}/api_porders.php?action=get_currency_orders&currency=${
            type !== "israel-warehouse" ? "usd" : "ils"
          }`
          : `${BASE_URL}/api_porders.php?action=get_currency_orders&currency=${
            type !== "israel-warehouse" ? "usd" : "ils"
          }&type=${tab}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Orders successfully fetched:", response.data);
      setOrders(response.data.orders);
      setLoading(false);
    } catch (error) {
      setOrders([]);
      setLoading(false);
      console.error("Error fetching orders:", error);
    }
  };

  const handleStatusChange = (e) => {
    console.log(e);
    setDeliveryStatus(e);
  };

  const handleTrackingIdChange = (e) => {
    setTrackingId(e.target.value);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
    setTrackingId(""); // Resetting state when modal closes
    setDeliveryStatus(""); // Resetting state when modal closes
  };

  const handleSaveTrackingId = async () => {
    try {
      const updatedOrder = {
        order_id: selectedOrder.id,
        tracking_id: trackingId,
        delivery_status: admin_status_enum(deliveryStatus),
      };
      console.log(updatedOrder);

      // const promises = [];

      if (selectedOrder.tracking_id !== trackingId) {
        const response_tracking = axios.put(
          `${BASE_URL}/api_porders.php?action=update_tracking_status`,
          {
            order_id: updatedOrder.order_id,
            tracking_id: updatedOrder.tracking_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response_tracking.data);
      }

      if (selectedOrder.delivery_status !== deliveryStatus) {
        const response_status = await axios.put(
          `${BASE_URL}/api_porders.php?action=update_tracking_status`,
          {
            order_id: updatedOrder.order_id,
            delivery_status: updatedOrder.delivery_status,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response_status.data);
      }

      // await Promise.all(promises);
      console.log("Updates successful");

      const updatedOrders = orders.map((order) =>
        order.id === updatedOrder.order_id
          ? {
              ...order,
              tracking_id: updatedOrder.tracking_id,
              delivery_status: updatedOrder.delivery_status,
            }
          : order
      );

      setOrders(updatedOrders);
      setModalOpen(false); // Close modal after saving
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };
  const handleViewInvoice = async (order) => {
    setSelectedOrder(order);
    // await fetchOrderDetails(order.id);
    // setSelectedInvoice(order);
    setShowInvoiceModal(true);
  };
  const printRef = useRef(null); // Initialize the ref

  const handlePrint = useReactToPrint({
    documentTitle: "Invoice",
    contentRef: printRef,
  });
  return (
    <div>
      <div className="flex space-x-4 mb-4 bg-gray-100 p-2 rounded-lg shadow-md">
        {[
          "all_orders",
          "users_orders",
          "distributors_orders",
          "printPages",
        ].map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setActiveTab(tab);
              setLoading(true);
              setOrders([]);
              GetOrder(
                tab === "all_orders"
                  ? null
                  : tab === "users_orders"
                  ? "user"
                  : tab === "distributors_orders"
                  ? "distributer"
                  : "printPages"
              );
            }}
            className={`px-6 py-3 rounded-lg font-semibold transition-all duration-300 
          ${
            activeTab === tab
              ? "bg-gradient-to-r from-[#6D3815] to-[#9C5A2A] text-white shadow-lg scale-105"
              : "bg-white text-gray-700 hover:bg-gray-200 hover:shadow-md"
          }`}
          >
            {t(tab)}
          </button>
        ))}
      </div>

      {loading ? (
        <tr>
          <div className="flex items-center justify-center min-h-screen">
            <p className="text-center items-center justify-center text-blue-500 text-xl">
              {t("loading")}
            </p>
          </div>
        </tr>
      ) : orders && orders.length > 0 ? (
        <>
          {" "}
          {activeTab === "all_orders" && (
            <>
              {" "}
              <h1 className="text-2xl font-bold mb-4">{t("all_orders")}</h1>
              <table className="border-collapse w-full border border-gray-200 mt-4">
                <thead className="bg-[#fde5c3c4] text-left">
                  <tr>
                    <th className="border border-gray-300 p-2">
                      {t("order_id")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("customer_name")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("currency")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("total_price")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("status")}
                    </th>
                    {/* <th className="border border-gray-300 p-2">{t("track_id")}</th> */}
                    <th className="border border-gray-300 p-2">
                      {t("action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="border border-gray-300 p-2">
                        PHY-ORD-{order.id}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.username}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {t(order.currency)}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.currency !== "ils" ? "$" : "₪"}{" "}
                        {order.final_amount}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {t(order.delivery_status)}
                      </td>
                      {/* <td className="border border-gray-300 p-2">{order.tracking_id}</td> */}
                      <td className="border border-gray-300 p-2">
                        <button
                          onClick={() => handleEdit(order)}
                          className="bg-[#6D3815] w-full text-white flex justify-center items-center rounded px-2 py-2"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleViewInvoice(order)}
                          className="bg-[#6D3815] hover:bg-[#5a2f10] w-full text-white rounded px-2 py-1 mt-2 transition-all duration-200"
                        >
                          {t("view_invoice")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {activeTab === "users_orders" && (
            <>
              {" "}
              <h1 className="text-2xl font-bold mb-4">{t("users_orders")}</h1>
              <table className="border-collapse w-full border border-gray-200 mt-4">
                <thead className="bg-[#fde5c3c4] text-left">
                  <tr>
                    <th className="border border-gray-300 p-2">
                      {t("order_id")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("customer_name")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("currency")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("total_price")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("status")}
                    </th>
                    {/* <th className="border border-gray-300 p-2">{t("track_id")}</th> */}
                    <th className="border border-gray-300 p-2">
                      {t("action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="border border-gray-300 p-2">
                        PHY-ORD-{order.id}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.username}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {t(order.currency)}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.currency !== "ils" ? "$" : "₪"}{" "}
                        {order.final_amount}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {t(order.delivery_status)}
                      </td>
                      {/* <td className="border border-gray-300 p-2">{order.tracking_id}</td> */}
                      <td className="border border-gray-300 p-2">
                        <button
                          onClick={() => handleEdit(order)}
                          className="bg-[#6D3815] w-full text-white flex justify-center items-center rounded px-2 py-2"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleViewInvoice(order)}
                          className="bg-[#6D3815] hover:bg-[#5a2f10] w-full text-white rounded px-2 py-1 mt-2 transition-all duration-200"
                        >
                          {t("view_invoice")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {activeTab === "distributors_orders" && (
            <>
              {" "}
              <h1 className="text-2xl font-bold mb-4">
                {t("distributors_orders")}
              </h1>
              <table className="border-collapse w-full border border-gray-200 mt-4">
                <thead className="bg-[#fde5c3c4] text-left">
                  <tr>
                    <th className="border border-gray-300 p-2">
                      {t("order_id")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("customer_name")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("currency")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("total_price")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("status")}
                    </th>
                    {/* <th className="border border-gray-300 p-2">{t("track_id")}</th> */}
                    <th className="border border-gray-300 p-2">
                      {t("action")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="border border-gray-300 p-2">
                        PHY-ORD-{order.id}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.username}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {t(order.currency)}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.currency !== "ils" ? "$" : "₪"}{" "}
                        {order.final_amount}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {t(order.delivery_status)}
                      </td>
                      {/* <td className="border border-gray-300 p-2">{order.tracking_id}</td> */}
                      <td className="border border-gray-300 p-2">
                        <button
                          onClick={() => handleEdit(order)}
                          className="bg-[#6D3815] w-full text-white flex justify-center items-center rounded px-2 py-2"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleViewInvoice(order)}
                          className="bg-[#6D3815] hover:bg-[#5a2f10] w-full text-white rounded px-2 py-1 mt-2 transition-all duration-200"
                        >
                          {t("view_invoice")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {activeTab === "printPages" && (
            <>
              {" "}
              <h1 className="text-2xl font-bold mb-4">{t("printPages")}</h1>
              <table className="border-collapse w-full border border-gray-200 mt-4">
                <thead className="bg-[#fde5c3c4] text-left">
                  <tr>
                    <th className="border border-gray-300 p-2">
                      {t("order_id")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("customer_name")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("currency")}
                    </th>
                    <th className="border border-gray-300 p-2">
                      {t("total_price")}
                    </th>
                    {/* <th className="border border-gray-300 p-2">{t("status")}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="border border-gray-300 p-2">
                        PHY-ORD-{order.id}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.username}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {t(order.currency)}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {order.currency !== "ils" ? "$" : "₪"}{" "}
                        {order.final_amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </>
      ) : (
        <>
          <tr>
            <td colSpan="5" className="text-center p-4">
              {t("noOrdersFound")}
            </td>
          </tr>
        </>
      )}

      {isModalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[80%]">
            <h2 className="text-xl font-bold mb-4">Edit Order</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label className="block mb-2">{t("order_id")}</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  PHY-ORD-{selectedOrder.id}
                </div>
              </div>
              <div>
                <label className="block mb-2">{t("customer_name")}</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.username}
                </div>
              </div>

              <div>
                <label className="block mb-2 px-2">{t("status")}</label>
                <select
                  value={delivery_status_enum(deliveryStatus)} // Converts DB status to admin-friendly status
                  onChange={(e) =>
                    handleStatusChange(admin_status_enum(e.target.value))
                  } // Converts admin status back to DB format
                  className="border-b-2 bg-gray-300 border-gray-300 rounded p-2 w-full"
                >
                  {deliveryStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-3 overflow-auto max-h-[300px]">
                <div className="col-span-3 overflow-auto max-h-[300px]">
                  <div className="border-b-2 border-gray-300 p-2">
                    <div className="flex justify-between py-2 border-b-2 border-gray-100 items-center">
                      <span className="text-gray-500 text-center flex-1">
                        {t("item")}
                      </span>
                      <span className="text-gray-500 text-center flex-1">
                        {t("price")}
                      </span>
                      <span className="text-gray-500 text-center flex-1">
                        {t("qty")}
                      </span>
                      <span className="text-gray-500 text-center flex-1">
                        {t("total_price")}
                      </span>
                    </div>
                    {selectedOrder.details?.map((item) => (
                      <div
                        className="flex justify-between py-2 border-b-2 border-gray-100 items-center"
                        key={item.id}
                      >
                        <div className="flex flex-col items-center text-gray-500 flex-1">
                          <span>{htmlToPlainText(item.title)}</span>
                          {!item.paid && (
                            <span className="text-sm text-gray-400">
                              {item.SKU || `SKU-P-PAGE`}
                            </span>
                          )}
                        </div>

                        <span className="text-gray-500 text-center flex-1">
                          {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                          {item.price}
                        </span>
                        <span className="text-gray-500 text-center flex-1">
                          {item.quantity}
                        </span>
                        <span className="text-gray-500 text-center flex-1">
                          {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                          {(item.price * item.quantity).toFixed(2)}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 mt-6 gap-4">
              <div>
                <label className="block mb-2">{t("track_id")}</label>
                <input
                  type="text"
                  value={trackingId}
                  onChange={handleTrackingIdChange}
                  className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2 w-full"
                />
              </div>
              <div>
                <label className="block mb-2">{t("trans_id")}</label>
                <div className="border-b-2 shadow text-gray-500 px-2 border-gray-300 p-2">
                  {selectedOrder.transaction_id}
                </div>
              </div>
              <div>
                 <label className="block mb-2">{t("delivery_address")}</label>
                               <div className="border-b-2 shadow text-gray-500 px-2 border-gray-300 p-2">
                                 {selectedOrder.Shipping_Address}
                               </div>
                             </div>
                             <div>
                               <label className="block mb-2">{t("billing_address")}</label>
                               <div className="border-b-2 text-gray-500 border-gray-300 p-2">
                                 {selectedOrder.Billing_Address}
                               </div>
                             </div>
              <div>
                <label className="block mb-2">{t("currency")}</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.currency.toUpperCase()}
                </div>
              </div>
              <div>
                <label className="block mb-2">{t("payment_method")}</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.payment_mode ?? "Card Payment"}
                </div>
              </div>
              <div>
                <label className="block mb-2">{t("order_date")}</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.orderDate}
                </div>
              </div>
              <div>
                <label className="block mb-2">{t("total_price")}</label>
                <div className="border-b-2 text-gray-500 shadow px-2 border-gray-300 p-2">
                  {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                  {selectedOrder.final_amount}
                </div>
              </div>
            </div>
            <button
              onClick={handleSaveTrackingId}
              className="mt-4 bg-blue-500 text-white p-2 rounded"
            >
              {t("save")}
            </button>
            <button
              onClick={closeModal}
              className="mt-4 ml-2 bg-red-500 text-white p-2 rounded"
            >
              {t("close")}
            </button>
          </div>
        </div>
      )}
      {showInvoiceModal && selectedOrder && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          dir={i18n.dir()}
        >
          <div
            className="bg-white rounded-lg shadow-lg p-6 w-[800px] max-h-90vh] "
            id="invoice-modal-content"
          >
            <div // Attach ref here
              className="  max-h-[80vh] overflow-y-auto"
            >
              <div ref={printRef}>
                <InvoiceTemplate order={selectedOrder} />
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={handlePrint}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-800"
              >
                {t("print")}
              </button>
              <button
                onClick={() => {
                  setShowInvoiceModal(false);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-800"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveOrder;
