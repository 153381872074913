import React, { useEffect, useState } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Select from "react-select";
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "../Pages/LoadingPopup";
import { Markup } from "interweave";
import htmlToPlainText from "../htmltotext";
import NODE_BASE_URL from "../node_constant";
import { t } from "i18next";

const AddVol = () => {
  const [data, setData] = useState([]);
  const [selectedfiles, setSelectedFiles] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [volumes, setVolumes] = useState([]);
  const [books, setBookList] = useState([]);
  const [booksData, setBookDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setSelectedFile(file);
    if (selectedFile) {
      console.log("Selected File:", selectedFile);
    } else {
      console.log("No file selected");
    }
  };

  useEffect(() => {
    fetchVolumes();
    fetchBooks();
    fetchPdfs();
  }, []);
  const handlefileChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedFiles(selectedOption ? selectedOption.label : null);
  };
  const fetchPdfs = async () => {
    try {
      let url = `${BASE_URL}/add_files.php?type=searchable`;
      const response = await axios.get(url);
      const bookData = response.data.results;
      console.log(response.data.files);
      console.log(response.data.files);

      if (Array.isArray(response.data.files)) {
        // Create options for the dropdown
        const fileOptions = response.data.files.map((file, index) => ({
          value: index, // or any other unique identifier
          label: file,
        }));

        // Assuming you have a state setter for these options
        setData(fileOptions); // Replace with the appropriate state setter
      } else {
        console.error("Expected an array of pdfs");
      }
    } catch (error) {
      console.error("Error fetching pdfs:", error);
    }
  };
  const fetchBooks = async () => {
    try {
      let url = `${BASE_URL}/api_book.php/books`;
      const response = await axios.get(url);
      const bookData = response.data;
      console.log(response.data);
      if (Array.isArray(bookData)) {
        setBookDataList(bookData);
        const bookOptions = bookData.map((book) => ({
          value: book.id,
          label: htmlToPlainText(book.title),
        }));
        setBookList(bookOptions);
      } else {
        console.error("Expected an array of books");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  const fetchVolumes = async () => {
    try {
      let url = `${BASE_URL}/api_volumes.php`;
      const response = await axios.get(url);
      const volumeData = response.data;
      console.log(response.data);
      if (Array.isArray(volumeData)) {
        setVolumes(volumeData);
      } else {
        console.error("Expected an array of volumes");
      }
    } catch (error) {
      console.error("Error fetching volumes:", error);
    }
  };

  const handleBookChange = (selectedOption) => {
    setSelectedBook(selectedOption ? selectedOption.value : null);
  };

  // State for editing an existing volume
  const [editIndex, setEditIndex] = useState(null);
  const [editName, setEditName] = useState("");
  const [editBooks, setEditBooks] = useState("");
  const [editPrice, setEditPrice] = useState(0);
  const [editPriceIs, setEditPriceIs] = useState(0);
  const [editQuantity, setEditQuantity] = useState(0);
  const [editQuantityIs, setEditQuantityIs] = useState(0);
  const [editVolumeMaterial, setEditVolumeMaterial] = useState("");
  const [editMustBuy, setEditMustBuy] = useState(false);
  const [editSKU, seteditSKU] = useState("");
  const [editwholePrice, setEeditwholePrice] = useState(0);
  const [editwholePriceIs, setEditwholePriceIs] = useState(0);
  

  // State for adding a new volume
  const [showAddModal, setShowAddModal] = useState(false);
  const [newVolumeName, setNewVolumeName] = useState("");
  const [newVolumeMaterial, setNewVolumeMaterial] = useState("");
  const [newVolumePrice, setNewVolumePrice] = useState(0);
  const [newPriceIs, setNewPriceIs] = useState(0);
  const [newQuantity, setnewQuantity] = useState(0);
  const [newQuantityIs, setnewQuantityIs] = useState(0);
  const [selectedBook, setSelectedBook] = useState("");
  const [newMustBuy, setNewMustBuy] = useState(false);
  const [newSKU, setSKU] = useState("");
  const [newwholeVolumePriceIs, setNewwholeVolumePriceIs] = useState(0);
  const [newwholeVolumePrice, setNewwholeVolumePrice] = useState(0);

  // Function to open modal for editing an existing volume
  const handleEdit = (vol) => {
    setEditIndex(vol.id);
    setEditName(vol.title);
    setEditBooks(vol.book_id);
    setEditPrice(vol.price);
    setEditMustBuy(vol.must_buy != "1" ? false : true);
    setEditPriceIs(vol.price_is);
    setEditQuantity(vol.warehouse_quantity);
    setEditQuantityIs(vol.warehouse_quantity_is);
    setEditVolumeMaterial(vol.material);
    seteditSKU(vol.asin);
  };
  const handleDelete = async (id) => {
    try {
      const url = `${BASE_URL}/api_volumes.php?id=${id}`;
      await axios.delete(url);

      setVolumes(volumes.filter((book) => book.id !== id));
      const del_url = `${NODE_BASE_URL}/index/vol-${id}`;
      const del_response = await axios.delete(del_url);
      console.log(del_response);
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };
  async function indexBook(book) {
    const insertUrl = `${NODE_BASE_URL}/indexpdfviewer`;
    const updatedPdfLocation = book.pdf_location.includes("uploads/searchable/")
      ? book.pdf_location
      : `uploads/searchable/${book.pdf_location}`;

    const insertResponse = await axios.post(insertUrl, {
      pdfFiles: [{ ...book, pdf_location: updatedPdfLocation }],
    });
    console.log(insertResponse);
  }
  // Function to close modal and save edited data
  const handleCloseEditModal = async () => {
    if (editIndex !== null) {
      const formData = new FormData();
      formData.append("title", editName);
      formData.append("book_id", editBooks);
      formData.append("price", editPrice);
      formData.append("price_is", editPriceIs);
      formData.append("wholesale_price", editwholePrice);
      formData.append("wholesale_price_is", editwholePriceIs);
      formData.append("warehouse_quantity", editQuantity);
      formData.append("warehouse_quantity_is", editQuantityIs);
      formData.append("material", editVolumeMaterial);
      formData.append("must_buy", editMustBuy ? "1" : "0");
      formData.append("pdf_location", selectedfiles);
      formData.append("asin", editSKU);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const updatedVolume = {
        id: editIndex,
        title: editName,
        book_id: editBooks,
        price: editPrice,
        material: editVolumeMaterial,
        pdf_location: selectedfiles,
        asin: editSKU,
      };
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${BASE_URL}/api_volumes.php?id=${editIndex}`,
          formData,
          config
        );
        if (response.status === 200) {
          if (selectedfiles !== "") {
            try {
              const del_url = `${NODE_BASE_URL}/index/vol-${editIndex}`;
              const del_response = await axios.delete(del_url);
              console.log(del_response);

              const auther = booksData.find(
                (book) => book.id === selectedBook
              ).auther;
              const volData = {
                id: `vol-${editIndex}`,
                title: editName,
                auther: auther,
                book_id: selectedBook,
                price: editPrice,
                price_is: editPriceIs,
                wholesale_price: editwholePrice,
                wholesale_price_is: editwholePriceIs,
                warehouse_quantity: editQuantity,
                warehouse_quantity_is: editQuantityIs,
                material: editVolumeMaterial,
                pdf_location: selectedfiles,
                must_buy: editMustBuy ? "1" : "0",
                asin: editSKU,
              };
              // Assuming `insertedBook.book` contains the required data
              await indexBook(volData);
              console.log("Book indexed successfully");
            } catch (error) {
              console.error("Error indexing the book:", error);
            }
          } else {
            console.warn("No files selected");
          }
          const selectedBook = books.find((book) => book.value === editBooks);
          const updatedVolumes = volumes.map((vol) =>
            vol.id === updatedVolume.id
              ? { ...updatedVolume, book: selectedBook.label }
              : vol
          );
          setVolumes(updatedVolumes);
          fetchVolumes();
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error updating volume:", error);
      }
    }
    resetEditState();
  };

  // Function to reset edit state and close modal
  const resetEditState = () => {
    setEditIndex(null);
    setEditName("");
    setEditBooks("");
    setEditPrice(0);
    setEditVolumeMaterial("");
  };

  // Function to open modal for adding a new volume
  const openAddModal = () => {
    setShowAddModal(true);
    setNewVolumeName("");
    setSelectedBook(null);
    setNewVolumeMaterial("");
    setNewVolumePrice(0);
  };

  // Function to close modal for adding a new volume
  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setNewVolumeName("");
    setSelectedBook(null);
    setNewVolumeMaterial("");
    setNewVolumePrice(0);
  };

  const handleAddVolume = async () => {
    if (selectedBook) {
      const formData = new FormData();
      formData.append("title", newVolumeName);
      formData.append("book_id", selectedBook);
      formData.append("price", newVolumePrice);
      formData.append("price_is", newPriceIs);
      formData.append("wholesale_price", newwholeVolumePrice);
      formData.append("wholesale_price_is", newwholeVolumePriceIs);
      formData.append("warehouse_quantity", newQuantity);
      formData.append("warehouse_quantity_is", newQuantityIs);
      formData.append("material", newVolumeMaterial);
      formData.append("pdf_location", selectedfiles);
      formData.append("must_buy", newMustBuy ? "1" : "0");
      formData.append("asin", newSKU);
      // const newVolume = {
      //     title: newVolumeName,
      //     book_id: selectedBook,
      //     price: newVolumePrice,
      //     material: newVolumeMaterial
      // };
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${BASE_URL}/api_volumes.php`,
          formData,
          config
        );
        if (response.status === 201) {
          if (selectedfiles !== "") {
            try {
              const auther = booksData.find(
                (book) => book.id === selectedBook
              ).auther;
              const volData = {
                id: `vol-${response.data.id}`,
                title: newVolumeName,
                auther: auther,
                book_id: selectedBook,
                price: newVolumePrice,
                price_is: newPriceIs,
                wholesale_price: newwholeVolumePrice,
                wholesale_price_is:newwholeVolumePriceIs,
                warehouse_quantity: newQuantity,
                warehouse_quantity_is: newQuantityIs,
                material: newVolumeMaterial,
                pdf_location: selectedfiles,
                must_buy: newMustBuy ? "1" : "0",
                asin: newSKU,
              };
              // Assuming `insertedBook.book` contains the required data
              await indexBook(volData);
              console.log("Book indexed successfully");
            } catch (error) {
              console.error("Error indexing the book:", error);
            }
          } else {
            console.warn("No files selected");
          }

          fetchVolumes();
          setShowAddModal(false); // Close modal after adding
          setNewVolumeName("");
          setSelectedBook(null);
          setNewVolumeMaterial("");
          setSKU("");
          setNewVolumePrice(0);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error adding volume:", error);
      }
    }
  };

  return (
    <>
      <h2 className="text-2xl font-semibold">{t("volume_list")}</h2>
      <button
        className="bg-[#6D3815] hover:bg-black text-white px-4 py-2 rounded mt-4"
        onClick={openAddModal}
      >
        {t("add_volume")}
      </button>
      <div className="overflow-y-auto max-h-[600px]">
        <table className="border-collapse w-full border border-gray-200 mt-4">
          <thead className="bg-[#fde5c3c4]  text-left">
            <tr>
              <th className="border border-gray-200 px-4 py-2">{t("volume_name")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("books")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("sku")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("price")}</th>
              <th className="border border-gray-200 px-4 py-2">{t("action")}</th>
            </tr>
          </thead>
          <tbody>
            {volumes.map((vol, index) => (
              <tr key={vol.id}>
                <td className="border border-gray-200 px-4 py-2">
                  {vol.title}
                </td>
                {/* <Markup content={book.title}  /> </td> */}
                <td className="border border-gray-200 px-4 py-2">
                  <Markup content={vol.book} />{" "}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                  {vol.asin ?? "none"}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                  {vol.price}
                </td>
                <td className="border border-gray-200 px-4 py-2">
                  <button
                    className="text-blue-500 hover:text-blue-600 mr-2"
                    onClick={() => handleEdit(vol)} // Open modal for editing
                  >
                    <AiOutlineEdit className="inline-block" />
                  </button>
                  <button
                    className="text-red-500 hover:text-red-600"
                    onClick={() => handleDelete(vol.id)}
                  >
                    <AiOutlineDelete className="inline-block" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Modal for Editing an Existing Volume */}
      {editIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-[80%] p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">{t("edit_volume_details")}</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("volume_name")}
              </label>
              <input
                type="text"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                 {t("priceUSA")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editPrice}
                  onChange={(e) => setEditPrice(parseFloat(e.target.value))}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("priceIsrael")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editPriceIs}
                  onChange={(e) => setEditPriceIs(parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                 {t("wholesalePriceUSA")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editwholePrice}
                  onChange={(e) => setEeditwholePrice(parseFloat(e.target.value))}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
               {t("wholesalePriceIsrael")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editwholePriceIs}
                  onChange={(e) => setEditwholePriceIs(parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                  {t("quantity_usa")}
                </label>
                <input
                  type="number"
                  //   step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editQuantity}
                  onChange={(e) => setEditQuantity(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("quantity_israel")}
                </label>
                <input
                  type="number"
                  //   step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={editQuantityIs}
                  onChange={(e) => setEditQuantityIs(e.target.value)}
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="mb-4 form-in">
                <label
                  htmlFor="pdf"
                  className="block text-sm font-medium text-gray-700"
                >
                  {t("pdf")}{" "}
                </label>
                <Select
                  id="pdfs"
                  name="pdfs"
                  options={data}
                  placeholder={t("search")}
                  value={
                    selectedfiles
                      ? data.find((book) => selectedfiles.includes(book.label))
                      : null
                  }
                  // value={selectedfiles}
                  onChange={handlefileChange}
                  className="w-full"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("must_buy")}
              </label>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={editMustBuy}
                  onChange={(e) => setEditMustBuy(e.target.checked)}
                  className="mr-2"
                />
                <span className="text-sm text-gray-700">{t("mark_as_must_buy")}</span>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("sku")}
              </label>
              <input
                type="text"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={editSKU}
                onChange={(e) => seteditSKU(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("material")}
              </label>
              <input
                type="text"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={editVolumeMaterial}
                onChange={(e) => setEditVolumeMaterial(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("books")}
              </label>
              <Select
                options={books}
                placeholder={t("search")}
                value={books.find((book) => book.value === editBooks)}
                onChange={(selectedOption) =>
                  setEditBooks(selectedOption ? selectedOption.value : "")
                }
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              onClick={handleCloseEditModal}
            >
              {t("save_changes")}
            </button>
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 ml-2 rounded"
              onClick={resetEditState}
            >
              {t("close")}
            </button>
          </div>
        </div>
      )}

      {/* Modal for Adding a New Volume */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-[80%] p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">{t("add_new_volume")}</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
               {t("volume_name")}
              </label>
              <input
                type="text"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={newVolumeName}
                onChange={(e) => setNewVolumeName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("must_buy")}
              </label>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={newMustBuy}
                  onChange={(e) => setNewMustBuy(e.target.checked)}
                  className="mr-2"
                />
                <span className="text-sm text-gray-700">{t("mark_as_must_buy")}</span>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("sku")}
              </label>
              <input
                type="text"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={newSKU}
                onChange={(e) => setSKU(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
      {t("material")}
              </label>
              <input
                type="text"
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                value={newVolumeMaterial}
                onChange={(e) => setNewVolumeMaterial(e.target.value)}
              />
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                  {t("price_usa")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={newVolumePrice}
                  onChange={(e) =>
                    setNewVolumePrice(parseFloat(e.target.value))
                  }
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                  {t("price_israel")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={newPriceIs}
                  onChange={(e) => setNewPriceIs(parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("wholesale_price_usa")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={newwholeVolumePrice}
                  onChange={(e) =>
                    setNewwholeVolumePrice(parseFloat(e.target.value))
                  }
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("wholesale_price_israel")}
                </label>
                <input
                  type="number"
                  step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={newwholeVolumePriceIs}
                  onChange={(e) => setNewwholeVolumePriceIs(parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="flex space-x-4 mb-4">
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                  {t("quantity_usa")}
                  {/* Qunatity in USA Warehouse */}
                </label>
                <input
                  type="number"
                  //   step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={newQuantity}
                  onChange={(e) => setnewQuantity(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label className="block text-sm font-medium text-gray-700">
                {t("quantity_israel")}
                  {/* Qunatity in Israel Warehouse */}
                </label>
                <input
                  type="number"
                  //   step="0.01"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                  value={newQuantityIs}
                  onChange={(e) => setnewQuantityIs(e.target.value)}
                />
              </div>
            </div>
            {/* <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Price</label>
                            <input
                                type="number"
                                step="0.01"
                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
                                value={newVolumePrice}
                                onChange={(e) => setNewVolumePrice(parseFloat(e.target.value))}
                            />
                        </div> */}
            <div className="mb-4 form-in">
              <label
                htmlFor="pdf"
                className="block text-sm font-medium text-gray-700"
              >
                {t("pdf")}{" "}
              </label>
              <Select
                id="pdfs"
                name="pdfs"
                options={data}
                value={
                  selectedfiles
                    ? data.find((book) => selectedfiles.includes(book.label))
                    : null
                }
                // value={selectedfiles}
                onChange={handlefileChange}
                placeholder={t("search")}
                className="w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {t("books")}
              </label>
              <Select
                options={books}
                value={
                  selectedBook
                    ? books.find((book) => book.value === selectedBook)
                    : null
                }
                placeholder={t("search")}
                onChange={handleBookChange}
              />
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
              onClick={handleAddVolume}
            >
              {t("add_volume")}
            </button>
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 px-4 py-2 ml-2 rounded"
              onClick={handleCloseAddModal}
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      )}
      {isLoading && <LoadingPopup />}
    </>
  );
};

export default AddVol;
