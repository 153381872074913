import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../constant";
import { useTranslation } from "react-i18next";
import "../i18n"; // Import i18n configuration
import { BsTrash } from "react-icons/bs";
import Select from "react-select";

const CouponList = () => {
    const { t } = useTranslation();
    const [coupons, setCoupons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [users,setUsers]=useState([]);
     const [fuser, setfUser] = useState([]);
    useEffect(() => {
        Getusers()
      }, []);
      const Getusers=()=>{
        fetch(`${BASE_URL}/api_users.php?type=distributer`) // Replace with your API URL
        .then((res) => res.json())
        .then((data) => {
            if (!Array.isArray(data)) {
                throw new Error("Invalid data format: Expected an array");
              }
        
              const fileOptions = data.map((u) => ({
                value: u.id, // You can change this to u.Name, u.Phone, or u.Email if needed
                label: `${u.username} - ${u.email} - ${u.contact}`,
              }));
        
              setfUser(fileOptions);
              setUsers(data);
        })
        .catch((err) => console.error("Error fetching users:", err));
      };
    const [formData, setFormData] = useState({
        code: "",
        user_id: "",
        discount_type: "fixed",
        discount_value: "",
        min_order_amount: "",
        max_discount: "",
        expiry_date: "",
    });

    useEffect(() => {
        fetchCoupons();
    }, []);

    const fetchCoupons = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api_users_coupons.php?endpoint=get_all`);
            setCoupons(response.data.coupons);
        } catch (error) {
            setError(t("failedToLoad"));
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (id) => {
        if (!window.confirm("Are you sure you want to delete this coupon?")) {
            return;
        }

        try {
            const response = await axios.delete(`${BASE_URL}/api_users_coupons.php?endpoint=delete`, {
                data: { id } // Sending the coupon ID in the request body
            });

            if (response.data.success) {
                alert("Coupon deleted successfully");
                fetchCoupons(); // Refresh the list after deletion
            } else {
                alert(response.data.error);
            }
        } catch (error) {
            console.error("Error deleting coupon:", error);
            alert("Failed to delete coupon.");
        }
    };


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        const dataToSend = {
            ...formData,
            min_order_amount: formData.min_order_amount === "" ? null : formData.min_order_amount,
            max_discount:formData.max_discount === ""?null:formData.max_discount,
        };
        console.log(dataToSend);
        try {
            await axios.post(`${BASE_URL}/api_users_coupons.php?endpoint=create`, dataToSend);
            fetchCoupons();
            setIsModalOpen(false);
        } catch (error) {
            console.log(error);
            alert(t("failedToCreate"));
        }
    };

    return (
        <div className="flex flex-col items-center h-screen p-6">
            <div className="bg-white shadow-lg rounded-lg p-6 w-full relative">
                {/* Header with Create Coupon Button */}
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-gray-700">{t("title_coupons")}</h2>
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="bg-red-800 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                    >
                        {t("createCoupon")}
                    </button>
                </div>

                {/* Loading and Error Messages */}
                {loading ? (
                    <p className="text-center text-blue-500">{t("loading_coupons")}</p>
                ) : error ? (
                    <p className="text-center text-red-500">{error}</p>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                            <thead>
                                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                    {/* <th className="py-3 px-6 text-left">ID</th> */}
                                    <th className="py-3 px-6 text-left">{t("couponCode")}</th>
                                    <th className="py-3 px-6 text-left">{t("userPrefix")}</th>
                                    <th className="py-3 px-6 text-left">{t("discountType")}</th>
                                    <th className="py-3 px-6 text-left">{t("discountValue")}</th>
                                    <th className="py-3 px-6 text-left">{t("minOrder")}</th>
                                    <th className="py-3 px-6 text-left">{t("max_discount")}</th>
                                    <th className="py-3 px-6 text-left">{t("usage")}</th>
                                    <th className="py-3 px-6 text-left">{t("expiryDate")}</th>
                                    <th className="py-3 px-6 text-left">{t("status")}</th>
                                    <th className="py-3 px-6 text-left">{t("action")}</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-700 text-sm font-light">
                                {coupons.length > 0 ? (
                                    coupons.map((coupon) => (
                                        <tr key={coupon.id} className="border-b border-gray-200 hover:bg-gray-100">
                                            {/* <td className="py-3 px-6">{coupon.id}</td> */}
                                            <td className="py-3 px-6">{coupon.code}</td>
                                            <td className="py-3 px-6">{coupon.user_id ? `${t("userPrefix")} ${coupon.user_id}` : t("userGlobal")}</td>
                                            <td className="py-3 px-6">{t(coupon.discount_type)}</td>
                                            <td className="py-3 px-6">{coupon.discount_value}</td>
                                            <td className="py-3 px-6">{coupon.min_order_amount || "None"}</td>
                                            <td className="py-3 px-6">{coupon.max_discount || "None"}</td>
                                            <td className="py-3 px-6">{coupon.usage_limit ? `${coupon.times_used}/${coupon.usage_limit}` : "Unlimited"}</td>
                                            <td className="py-3 px-6">{coupon.expiry_date}</td>
                                            <td className="py-3 px-6">
                                                <span className={`px-3 py-1 rounded-full text-white ${coupon.status === "active" ? "bg-green-300" : "bg-red-300"}`}>
                                                    {coupon.status === "active" ? t("active") : t("inactive")}
                                                </span>
                                            </td>
                                            <td className="py-3 px-6 text-center">
                                        <button onClick={() => handleDelete(coupon.id)} style={{ color: "red" }}>
                                           <BsTrash/>
                                        </button>
                                    </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10" className="text-center py-4 text-gray-500">{t("noCoupons")}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {isModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">{t("create_coupon")}</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="code"
                    placeholder={t("coupon_code")}
                    required
                    className="w-full p-2 border rounded mb-2"
                    value={formData.code}
                    onChange={handleChange}
                />
               <Select
          id="users"
          name="users"
          options={fuser} // Ensure fuser contains { value, label } pairs
          onChange={(selectedOption) => {
            const selectedUser = users.find(
              (u) =>
                u.id === selectedOption.value ||
                u.username === selectedOption.value ||
                u.contact === selectedOption.value ||
                u.email === selectedOption.value
            );

            if (selectedUser) {
                console.log(selectedUser)
                setFormData({ ...formData, user_id: selectedUser.id||'' });
            //   setGuestInfo({
            //     name: selectedUser.Name || "",
            //     email: selectedUser.Email || "",
            //     phone: selectedUser.Phone || "",
            //     address: selectedUser.Billing_Address || "",
            //     shippingaddres: selectedUser.Shipping_Address || "",
            //   });
            //   setType(selectedUser.Type);
            }
          }}
          placeholder={
            // "Search"
            t("usersOptional")
          }
          className="w-full  rounded mb-2"
        />
                <select
                    name="discount_type"
                    className="w-full p-2 border rounded mb-2"
                    value={formData.discount_type}
                    onChange={handleChange}
                >
                    <option value="fixed">{t("fixed")}</option>
                    <option value="percent">{t("percent")}</option>
                </select>
                <input
                    type="number"
                    name="discount_value"
                    placeholder={t("discount_value")}
                    required
                    className="w-full p-2 border rounded mb-2"
                    value={formData.discount_value}
                    onChange={handleChange}
                />
                <input
                    type="number"
                    name="min_order_amount"
                    placeholder={t("min_order_amount_optional")}
                    className="w-full p-2 border rounded mb-2"
                    value={formData.min_order_amount || ""}
                    onChange={handleChange}
                />
                <input
                    type="number"
                    name="max_discount"
                    placeholder={t("max_discount_optional")}
                    className="w-full p-2 border rounded mb-2"
                    value={formData.max_discount || ""}
                    onChange={handleChange}
                />
               
                <input
                    type="date"
                    name="expiry_date"
                    required
                    className="w-full p-2 border rounded mb-2"
                    value={formData.expiry_date}
                    onChange={handleChange}
                />
                <div className="flex justify-between">
                    <button type="button" onClick={() => setIsModalOpen(false)} className="bg-gray-400 text-white px-4 py-2 rounded">
                        {t("cancel")}
                    </button>
                    <button type="submit" className="bg-red-900 hover:bg-red-600 text-white px-4 py-2 rounded">
                        {t("create")}
                    </button>
                </div>
            </form>
        </div>
    </div>
)}

        </div>
    );
};

export default CouponList;
