import { t } from "i18next";
import React, { useEffect, useState } from "react";


const InvoiceTemplate = ({order}) => {
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    if (order) {
      let formattedItems = order.details.map((item) => ({
        itemCode: `Book-${item.SKU}`,
        description: item.title.replace(/<[^>]*>/g, ""), // Remove HTML tags from title
        qty: item.quantity,
        price: parseFloat(item.price),
        amount: item.quantity * parseFloat(item.price),
      }));
      
      // Add Shipping Cost
      if (order.shipping_cost > 0) {
        formattedItems.push({
          itemCode: `Shipping`,
          description: 'Shipping Cost',
          qty: 1,
          price: parseFloat(order.shipping_cost),
          amount: parseFloat(order.shipping_cost),
        });
      }
      
      // Add Discount if applicable
      if (order.discount > 0) {
        formattedItems.push({
          itemCode: `Discount`,
          description: 'Discount On Call Order Or Coupon',
          qty: 1,
          price: -parseFloat(order.discount), // Discount should be negative
          amount: -parseFloat(order.discount),
        });
      }
      
      
      const totalAmount = formattedItems.reduce((acc, item) => acc + item.amount, 0);

      setInvoiceData({
        invoiceNumber: `OIZ-IN-${order.id}`,
        date: new Date(order.created_at||order.orderDate).toLocaleDateString("en-GB"),
        billTo: {
          name: order.username,
          address: order.address||order.Billing_Address,
          phone: order.contact||order.Phone,
        },
        shipTo: {
          name: order.username,
          address: order.shippingAddress ||order.Shipping_Address,
          phone: order.contact||order.Phone,
        },
        items: formattedItems,
        total: totalAmount,
        discount: parseFloat(order.discount),
        finalAmount: parseFloat(order.final_amount),
        currency: order.currency.toUpperCase(),
        status:order.Payment_Type,
        symbol:order.currency !== "ils" ? "$" : "₪",
        payments:order.final_amount,
        balanceDue: order.balanceDue||0,
      });
    }
  }, [order]); // Runs when order changes



  if (!invoiceData) return <p>{t("loading_invoice")}</p>;

  return (
    <div className="max-w-4xl mx-auto bg-white p-8 shadow-lg border border-gray-300 min-h-[1123px] flex flex-col">
      <div className="flex justify-between items-start pb-4 mb-4 border-b">
        <div>
          <div className="flex items-center space-x-4">
            <img src="../images/invoice-logo.png" alt="Company Logo" className="w-24 mb-2" />
            <div>
              <h1 className="text-lg font-bold">Oz Vehadar</h1>
              <p>PO Box 340</p>
              <p>Monsey NY 10952</p>
            </div>
          </div>
        </div>
        <div className="text-right w-48">
          <h1 className="text-xl font-bold border-b pb-1">{t("invoice")}</h1>
          <div className="grid grid-cols-2 mb-4"> 
            <div className="border border-gray-400">
              <h2 className="font-semibold p-2 bg-gray-200">{t("date")}</h2>
              <p className="px-2 text-sm">{invoiceData.date}</p>
            </div>
            <div className="border border-gray-400">
              <h2 className="font-semibold p-2 bg-gray-200">{t("invoice_number")}</h2>
              <p className="px-2 text-sm">{invoiceData.invoiceNumber}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow">
        <div className="grid grid-cols-2 gap-16 p-2 mb-4"> 
          <div className="border border-gray-400">
            <h2 className="font-semibold p-2 bg-gray-200">{t("bill_to")}</h2>
            <p className="px-2">{invoiceData.billTo.name}</p>
            <p className="px-2">{invoiceData.billTo.address}</p>
            <p className="px-2">{invoiceData.billTo.phone}</p>
          </div>
          <div className="border border-gray-400">
            <h2 className="font-semibold p-2 bg-gray-200">{t("ship_to")}</h2>
            <p className="px-2">{invoiceData.shipTo.name}</p>
            <p className="px-2">{invoiceData.shipTo.address}</p>
            <p className="px-2">{invoiceData.shipTo.phone}</p>
          </div>
        </div>

        <table className="w-full border-collapse border border-gray-400 mb-4 text-sm">
          <thead>
            <tr className="bg-gray-400 border">
              <th className="border p-2">{t("item_code")}</th>
              <th className="border p-2">{t("description")}</th>
              <th className="border p-2">{t("qty")}</th>
              <th className="border p-2">{t("price_each")}</th>
              <th className="border p-2">{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.items.map((item, index) => (
              <tr key={index} className={`text-center border ${index % 2 === 0 ? 'bg-gray-100' : ''}`}>
                <td className="border p-2">{item.itemCode}</td>
                <td className="border p-2 text-left">{item.description}</td>
                <td className="border p-2">{item.qty}</td>
                <td className="border p-2">{invoiceData.symbol}{item.price.toFixed(2)}</td>
                <td className="border p-2">{invoiceData.symbol}{item.amount.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="grid grid-cols-3 pt-4 text-lg">
        <div className="flex items-center border p-2">
          <p className="font-bold text-sm">{t("total")}</p>
          <span className="ml-2 text-sm">{invoiceData.symbol}{invoiceData.total.toFixed(2)}</span>
        </div>
        <div className="flex items-center border p-2">
          <p className="font-bold text-sm">{t("payments_credits")}</p>
          <span className="ml-2 text-sm">
            {invoiceData.symbol}
            {invoiceData.status !== "credit"
              ? invoiceData.finalAmount.toFixed(2)
              : (-invoiceData.payments).toFixed(2)}
          </span>
        </div>
        <div className="flex items-center border bg-gray-200 p-2">
          <p className="font-bold text-sm">{t("balance_due")}</p>
          <span className="ml-2 font-bold text-sm">
            {invoiceData.symbol}
            {invoiceData.status !== "credit"
              ? (invoiceData.finalAmount - invoiceData.payments).toFixed(2)
              : invoiceData.payments.toFixed(2)}
          </span>
        </div>
      </div>

      <br />

      <div className="mt-auto text-center text-sm text-gray-600 pt-4">
        <div className="flex justify-evenly">
          <p className="border-r pr-4 font-bold">{t("phone")}: 718 437 0054</p>
          <p className="border-r px-4 font-bold">{t("fax")}: 718 437 3514</p>
          <p className="border-r px-4 font-bold">{t("web")}: www.ozvehadar.us</p>
          <p className="pl-4 font-bold">{t("email")}: sales@oizusa.com</p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTemplate;
