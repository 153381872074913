import React, { useState, useEffect } from "react";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import BASE_URL from "../constant";
import axios from "axios";
import LoadingPopup from "../Pages/LoadingPopup";
import { t } from "i18next";

const HomeBookList = () => {
    const [books, setBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [newBookData, setNewBookData] = useState({
        name: '',
        link: '',
        author: '',
        category: ''
    });
    const [editedBook, setEditedBook] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewBookModalOpen, setIsNewBookModalOpen] = useState(false);
    const [filterCategory, setFilterCategory] = useState("");
    const [filterAuthor, setFilterAuthor] = useState("");
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchBooks();
        fetchTopics();
    }, []);

    const fetchBooks = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api_home.php/list_books`);
            setBooks(response.data);
        } catch (error) {
            console.error("Error fetching books:", error);
        }
    };

    const fetchTopics = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api_categories.php`);
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching topics:", error);
        }
    };

    const handleEdit = (book) => {
        setNewBookData({
            name: book.book_name,
            link: book.book_link,
            author: book.author_name,
            category: book.category_id
        });
        setEditedBook(book);
        setIsModalOpen(true);
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const params = new URLSearchParams();
            params.append('id', editedBook.id);
            params.append('category_id', newBookData.category);
            params.append('book_name', newBookData.name);
            params.append('book_link', newBookData.link);
            params.append('author_name', newBookData.author);

    
            const response = await axios.put(`${BASE_URL}/api_home.php/list_books`, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            console.log(response.data);
            // await axios.put(`${BASE_URL}/api_home.php/update_book`, editedBook);
            fetchBooks(); // Refetch books to update the list
            setIsModalOpen(false);
            setEditedBook({});
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error updating book:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (isModalOpen) {
            setNewBookData({ ...newBookData, [name]: value });
            setEditedBook({ ...editedBook, [name]: value });
        } else {
            setNewBookData({ ...newBookData, [name]: value });
        }
    };

    const handleDelete = async (id) => {
        try {
            const params = new URLSearchParams();
            params.append('id', id);
    
            const response = await axios.delete(`${BASE_URL}/api_home.php/list_books`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: params
            });
    
            console.log(response.data);
            setBooks(books.filter(book => book.id !== id));
        } catch (error) {
            console.error("Error deleting book:", error);
        }
    };

    const handleNewBookSubmit = async () => {
        try {
            setIsLoading(true);
            console.log( newBookData);
            const formData = new FormData();
      formData.append('author_name', newBookData.author);
      formData.append('book_link', newBookData.link);
      formData.append('book_name', newBookData.name);
      formData.append('category_id', newBookData.category);
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
            const response = await axios.post(`${BASE_URL}/api_home.php/list_books`, formData,config);
            console.log(response.data);
            // return response.data;
            // await axios.post(`${BASE_URL}/api_home.php/list_books`, newBookData);
            fetchBooks(); // Refetch books to update the list
            setIsNewBookModalOpen(false);
            setNewBookData({
                name: '',
                link: '',
                author: '',
                category: ''
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error adding new book:", error);
        }
    };

    const handleFilterCategoryChange = (e) => {
        setFilterCategory(e.target.value);
    };

    const handleFilterAuthorChange = (e) => {
        setFilterAuthor(e.target.value);
    };

    const handleFilterCategoryToggle = () => {
        setFilterCategory("");
    };

    const handleFilterAuthorToggle = () => {
        setFilterAuthor("");
    };

    return (
        <>
        <h1 className="text-3xl my-6">{t("homepage_list")}</h1>
        <button 
            onClick={() => { 
                setNewBookData({
                    name: "",
                    link: "",
                    author: "",
                    category: ""
                });
                setIsNewBookModalOpen(true);
            }} 
            className="bg-[#6D3815] hover:bg-black text-white font-bold py-2 px-4 rounded"
        >
            {t("add_new_book")}
        </button>

        <div className="flex my-4">
            <div className="mr-4">
                <label>
                    <input 
                        type="checkbox" 
                        onChange={handleFilterCategoryToggle} 
                        checked={filterCategory === ""} 
                        className="mr-2" 
                    />
                    {t("filter_by_category")}
                </label>
                <select
                    value={filterCategory}
                    onChange={handleFilterCategoryChange}
                    className="border mb-5 px-2 py-1 bg-gray-200 rounded-full ml-2"
                >
                    <option value="">{t("all")}</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.name}>{category.name}</option>
                    ))}
                </select>
            </div>
            <div>
                <label>
                    <input 
                        type="checkbox" 
                        onChange={handleFilterAuthorToggle} 
                        checked={filterAuthor === ""} 
                        className="mr-2" 
                    />
                    {t("filter_by_author")}
                </label>
                <select
                    value={filterAuthor}
                    onChange={handleFilterAuthorChange}
                    className="border mb-5 px-2 py-1 bg-gray-200 rounded-full ml-2"
                >
                    <option value="">{t("all")}</option>
                    {Array.from(new Set(books.map(book => book.author_name))).map(author => (
                        <option key={author} value={author}>{author}</option>
                    ))}
                </select>
            </div>
        </div>

        <div className="max-h-[400px] overflow-y-auto">
            <table className="table-auto w-full border-collapse border">
                <thead>
                    <tr className="bg-[#fde5c3c4] text-left">
                        <th className="border px-4 py-2">{t("book_name")}</th>
                        <th className="border px-4 py-2">{t("book_link")}</th>
                        <th className="border px-4 py-2">{t("author_name")}</th>
                        <th className="border px-4 py-2">{t("category")}</th>
                        <th className="border px-4 py-2">{t("edit")}</th>
                        <th className="border px-4 py-2">{t("delete")}</th>
                    </tr>
                </thead>
                <tbody>
                    {books
                        .filter(book => 
                            (filterCategory === "" || book.category_name === filterCategory) && 
                            (filterAuthor === "" || book.author_name === filterAuthor)
                        )
                        .map(book => (
                            <tr key={book.id}>
                                <td className="border px-4 py-2">{book.book_name}</td>
                                <td className="border px-4 py-2">{book.book_link}</td>
                                <td className="border px-4 py-2">{book.author_name}</td>
                                <td className="border px-4 py-2">{book.category_name}</td>
                                <td className="border px-4 py-2">
                                    <button 
                                        onClick={() => handleEdit(book)} 
                                        className="text-blue-500 hover:text-blue-800"
                                    >
                                        <BsPencilSquare />
                                    </button>
                                </td>
                                <td className="border px-4 py-2">
                                    <button 
                                        onClick={() => handleDelete(book.id)} 
                                        className="text-red-500 hover:text-red-800"
                                    >
                                        <BsTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>

        {/* Add New Book Modal */}
        {isNewBookModalOpen && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                <div className="bg-white p-6 rounded-md">
                    <h2 className="text-lg font-semibold mb-4">{t("add_new_book")}</h2>
                    <input type="text" name="name" value={newBookData.name} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" placeholder={t("book_name")} />
                    <input type="text" name="link" value={newBookData.link} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" placeholder={t("book_link")} />
                    <input type="text" name="author" value={newBookData.author} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" placeholder={t("author_name")} />
                    <select name="category" value={newBookData.category} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full">
                        <option value="">{t("select_category")}</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </select>
                    <button onClick={handleNewBookSubmit} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">{t("add_book")}</button>
                    <button onClick={() => setIsNewBookModalOpen(false)} className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md ml-2">{t("close")}</button>
                </div>
            </div>
        )}
            {/* Edit Book Modal */}
            {isModalOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-md">
                        <h2 className="text-lg font-semibold mb-4">{t("edit_book")}</h2>
                        <input type="text" name="name" value={newBookData.name} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" placeholder="Book Name" />
                        <input type="text" name="link" value={newBookData.link} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" placeholder="Book Link" />
                        <input type="text" name="author" value={newBookData.author} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full" placeholder="Author Name" />
                        <select name="category" value={newBookData.category} onChange={handleChange} className="border border-gray-300 rounded-md px-3 py-2 mb-2 w-full">
                        <option value="">{t("select_category")}</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                        <button onClick={handleSave} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">{t("save")}</button>
                        <button onClick={() => setIsModalOpen(false)} className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md ml-2">{t("close")}</button>
                    </div>
                </div>
            )}
    </>
    );
};

export default HomeBookList;
