import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

import AddBook from "./AddBook";
import Addcat from "./AddCategory";
import Library from "./LibrayData";
import Dashboard from "./Dashboard";
import HomeBookList from "./HomeListBooks";
import AddBundle from "./AddBundle";
import AddSubscription from "./AddSubs";
import AddDonation from "./AddDonations";
import AddVol from "./AddVol";
import Addindex from "./AddIndex";
import UploadPDF from "./AddPDF";
import Order from "./OrderView";
import ActiveOrder from "./ActiveOrders";
import Catalog from "./DownloadCata";
import ContactUsList from "./ContactUsList";
import ALLDonation from "./all_donation";
import FAQComponent from "./FAQComponent";
import UserBookList from "./UserInserted";
import NewArrivalApp from "./addnewArrival";
import UsersList from "./user&distributer";
import ManualOrderCreation from "./OfflineOrders";
import { t } from "i18next";
import CouponList from "./coupan";

const Admin = ({ isLoggedIn, handleLogout }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [expandedSections, setExpandedSections] = useState({
    books: false,
    orders: false,
    userManagement: false,
  });

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <div className="w-full flex m-auto" dir="ltr">
      {/* Sidebar */}
      <div
        style={{
          background:
            "linear-gradient(180deg, #F2F9FF 0%, rgba(255, 255, 255, 0.00) 100%)",
          borderRadius: "25px",
        }}
        className="w-[20%] p-4"
      >
        <div className="flex flex-col">
          {/* Dashboard */}
          <div
            onClick={() => handleTabClick(1)}
            className={`cursor-pointer px-4 py-2 rounded-r-xl ${
              activeTab === 1 ? "bg-orange-200 shadow-md" : ""
            }`}
          >
            {t("dashboard")}
          </div>

          {/* Books Management */}
          <div
            onClick={() => toggleSection("books")}
            className="cursor-pointer flex justify-between items-center px-4 py-2 font-semibold"
          >
            {t("books_management")}
            {expandedSections.books ? <FaChevronDown /> : <FaChevronRight />}
          </div>
          {expandedSections.books && (
            <div className="pl-6">
              {[
                { id: 2, name: "add_book" },
                { id: 3, name: "add_category" },
                { id: 5, name: "add_bundle" },
                { id: 6, name: "add_subscription" },
                { id: 7, name: "add_donations" },
                { id: 8, name: "add_volume" },
                { id: 9, name: "add_index" },
                { id: 13, name: "upload_pdf" },
                { id: 17, name: "library_data" },
                { id: 23, name: "add_new_arrivals" },
                { id: 12, name: "download_catalog" },
                { id: 4, name: "home_book_list" },
              ]
                // {[
                //   { id: 2, name: "Add Book" },
                //   { id: 3, name: "Add Category" },
                //   { id: 5, name: "Add Bundle" },
                //   { id: 6, name: "Add Subscription" },
                //   { id: 7, name: "Add Donations" },
                //   { id: 8, name: "Add Volume" },
                //   { id: 9, name: "Add Index" },
                //   { id: 13, name: "Upload PDF" },
                //   { id: 17, name: "Library Data" },
                //   { id: 23, name: "Add New Arrivals" },
                //   { id: 12, name: "Download Catalog" },
                // ]
                .map((tab) => (
                  <div
                    key={tab.id}
                    onClick={() => handleTabClick(tab.id)}
                    className={`cursor-pointer px-4 py-2 rounded-r-xl ${
                      activeTab === tab.id ? "bg-orange-200 shadow-md" : ""
                    }`}
                  >
                    {t(tab.name)}
                  </div>
                ))}
            </div>
          )}

          {/* Orders */}
          <div
            onClick={() => toggleSection("orders")}
            className="cursor-pointer flex justify-between items-center px-4 py-2 font-semibold"
          >
            {t("orders")}
            {expandedSections.orders ? <FaChevronDown /> : <FaChevronRight />}
          </div>
          {expandedSections.orders && (
            <div className="pl-6">
              {[
                { id: 10, name: "digital_orders" },
                { id: 11, name: "physical_orders" },
                { id: 25, name: "offline_orders_form" },
              ].map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  className={`cursor-pointer px-4 py-2 rounded-r-xl ${
                    activeTab === tab.id ? "bg-orange-200 shadow-md" : ""
                  }`}
                >
                  {t(tab.name)}
                </div>
              ))}
            </div>
          )}

          {/* User Management */}
          <div
            onClick={() => toggleSection("userManagement")}
            className="cursor-pointer flex justify-between items-center px-4 py-2 font-semibold"
          >
            {t("user_management")}
            {expandedSections.userManagement ? (
              <FaChevronDown />
            ) : (
              <FaChevronRight />
            )}
          </div>
          {expandedSections.userManagement && (
            <div className="pl-6">
              {[
                { id: 24, name: "distributors_list" },
                { id: 26, name: "coupons" },
                { id: 19, name: "contact_us_list" },
                { id: 20, name: "all_donations" },
                { id: 21, name: "faqs" },
                { id: 27, name: "users_and_distributors" },
                { id: 22, name: "user_book_list" },
              ].map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => handleTabClick(tab.id)}
                  className={`cursor-pointer px-4 py-2 rounded-r-xl ${
                    activeTab === tab.id ? "bg-orange-200 shadow-md" : ""
                  }`}
                >
                  {t(tab.name)}
                </div>
              ))}
            </div>
          )}
          <div
            onClick={() => {
              handleTabClick(18);
            }}
            className={`cursor-pointer px-4 py-2 rounded-r-xl ${
              activeTab === 18 ? "bg-orange-200 shadow-md" : ""
            }`}
          >
            {/* <p className="text-left justify-center pl-[18px] pr-[35px] py-[18px] text-black-900 text-lg font-lato font-normal"> */}
            {t("logout")}
            {/* </p> */}
          </div>
          {/* <ul className="side-menu">
          
          <li>
            <a href="#" className="logout"onClick={() => handleLogout(false) }>
              <i className='bx bxs-log-out-circle' ></i>
              <span className="text">Logout</span>
            </a>
          </li>
        </ul> */}
        </div>
      </div>

      {/* Main Content */}
      <div className="w-[80%] p-6">
        {activeTab === 1 && <Dashboard />}
        {activeTab === 2 && <AddBook />}
        {activeTab === 3 && <Addcat />}
        {activeTab === 4 && <HomeBookList />}
        {activeTab === 5 && <AddBundle />}
        {activeTab === 6 && <AddSubscription />}
        {activeTab === 7 && <AddDonation />}
        {activeTab === 8 && <AddVol />}
        {activeTab === 9 && <Addindex />}
        {activeTab === 10 && <Order />}
        {activeTab === 11 && <ActiveOrder />}
        {activeTab === 12 && <Catalog />}
        {activeTab === 13 && <UploadPDF />}
        {activeTab === 17 && <Library />}
        {activeTab === 19 && <ContactUsList />}
        {activeTab === 20 && <ALLDonation />}
        {activeTab === 21 && <FAQComponent />}
        {activeTab === 22 && <UserBookList />}
        {activeTab === 23 && <NewArrivalApp />}
        {activeTab === 24 && <UsersList />}
        {activeTab === 25 && (
          <ManualOrderCreation isAdmin={true} currency={"usd"} />
        )}
         {activeTab === 26 && (
          < CouponList isAdmin={true} currency={"usd"} />
        )}
       
        {activeTab === 18 && handleLogout(false)}
      </div>
    </div>
  );
};

export default Admin;
