import React, { useState, useEffect } from "react";
import BASE_URL from "../constant";
import axios from "axios";
import "react-quill/dist/quill.snow.css";
import { t } from "i18next";

const ALLDonation = () => {
  const [people, setPeople] = useState([]);
  const [selectedDonor, setSelectedDonor] = useState(null); // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility

  useEffect(() => {
    fetchDonations();
  }, []);

  const fetchDonations = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api_donations.php/donations`);
      console.log("Fetched Donations:", response.data);
      setPeople(response.data);
    } catch (error) {
      console.error("Error fetching donations:", error);
    }
  };

  const openModal = (donor) => {
    setSelectedDonor(donor);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedDonor(null);
    setIsModalOpen(false);
  };

  // return (
  //   <div className="container mx-auto p-4">
  //     <h2 className="text-2xl font-bold mb-4">Donations List</h2>

  //     {/* Donations Table */}
  //     <table className="min-w-full border border-gray-200 rounded-md">
  //       <thead>
  //         <tr className="bg-[#fde5c3c4]">
  //           <th className="border-b border-gray-200 px-4 py-2">ID</th>
  //           <th className="border-b text-left border-gray-200 px-4 py-2">Donor</th>
  //           <th className="border-b text-left border-gray-200 px-4 py-2">Amount</th>
  //           <th className="border-b text-left border-gray-200 px-4 py-2">Actions</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {people.length > 0 ? (
  //           people.map((person) => (
  //             <tr key={person.id} className="hover:bg-gray-50">
  //               <td className="border-b border-gray-200 px-4 py-2">{person.id}</td>
  //               <td className="border-b border-gray-200 px-4 py-2">
  //                 {person.donorName && person.donorName.trim() !== ""
  //                   ? person.donorName
  //                   : person.anonymous === "1"
  //                   ? "Anonymous Donor"
  //                   : "Unknown Donor"}
  //               </td>
  //               <td className="border-b border-gray-200 px-4 py-2">{person.amount}</td>
  //               <td className="border-b border-gray-200 px-4 py-2">
  //                 <button
  //                   onClick={() => openModal(person)}
  //                   className="text-blue-500 hover:underline"
  //                 >
  //                   View Details
  //                 </button>
  //               </td>
  //             </tr>
  //           ))
  //         ) : (
  //           <tr>
  //             <td colSpan="4" className="text-center text-gray-500 py-4">
  //               No donations found.
  //             </td>
  //           </tr>
  //         )}
  //       </tbody>
  //     </table>

  //     {/* Modal */}
  //     {isModalOpen && selectedDonor && (
  //       <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
  //         <div className="bg-white rounded-lg w-1/3 p-6 relative">
  //           <h3 className="text-xl font-bold mb-4">Donor Details</h3>
  //           <div className="space-y-2">
  //             <p>
  //               <strong>ID:</strong> {selectedDonor.id}
  //             </p>
  //             <p>
  //               <strong>Donor Name:</strong>{" "}
  //               {selectedDonor.donorName && selectedDonor.donorName.trim() !== ""
  //                 ? selectedDonor.donorName
  //                 : selectedDonor.anonymous === "1"
  //                 ? "Anonymous Donor"
  //                 : "Unknown Donor"}
  //             </p>
  //             <p>
  //               <strong>Amount:</strong> {selectedDonor.amount}
  //             </p>
  //             <p>
  //               <strong>Date:</strong> {selectedDonor.date}
  //             </p>
  //             <p>
  //               <strong>For Soul Elevation:</strong> {selectedDonor.forSoulElevation || "N/A"}
  //             </p>
  //             <p>
  //               <strong>For Healing:</strong> {selectedDonor.forHealing || "N/A"}
  //             </p>
  //             <p>
  //               <strong>For Salvation:</strong> {selectedDonor.forSalvation || "N/A"}
  //             </p>
  //             <p>
  //               <strong>For Success:</strong> {selectedDonor.forSuccess || "N/A"}
  //             </p>
  //             <p>
  //               <strong>Notes:</strong> {selectedDonor.notes || "N/A"}
  //             </p>
  //             <p>
  //               <strong>Created At:</strong> {selectedDonor.created_at}
  //             </p>
  //           </div>
  //           <button
  //             onClick={closeModal}
  //             className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
  //           >
  //             &times;
  //           </button>
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
// }
return (
  <div className="container mx-auto p-4">
    <h2 className="text-2xl font-bold mb-4">{t("donationsList")}</h2>

    {/* Donations Table */}
    <table className="min-w-full border border-gray-200 rounded-md">
      <thead>
        <tr className="bg-[#fde5c3c4]">
          <th className="border-b border-gray-200 px-4 py-2">{t("id")}</th>
          <th className="border-b text-left border-gray-200 px-4 py-2">{t("donor")}</th>
          <th className="border-b text-left border-gray-200 px-4 py-2">{t("amount")}</th>
          <th className="border-b text-left border-gray-200 px-4 py-2">{t("actions")}</th>
        </tr>
      </thead>
      <tbody>
        {people.length > 0 ? (
          people.map((person) => (
            <tr key={person.id} className="hover:bg-gray-50">
              <td className="border-b border-gray-200 px-4 py-2">{person.id}</td>
              <td className="border-b border-gray-200 px-4 py-2">
                {person.donorName && person.donorName.trim() !== ""
                  ? person.donorName
                  : person.anonymous === "1"
                  ? t("anonymousDonor")
                  : t("unknownDonor")}
              </td>
              <td className="border-b border-gray-200 px-4 py-2">{person.amount}</td>
              <td className="border-b border-gray-200 px-4 py-2">
                <button
                  onClick={() => openModal(person)}
                  className="text-blue-500 hover:underline"
                >
                  {t("viewDetails")}
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-center text-gray-500 py-4">
              {t("noDonationsFound")}
            </td>
          </tr>
        )}
      </tbody>
    </table>

    {/* Modal */}
    {isModalOpen && selectedDonor && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white rounded-lg w-1/3 p-6 relative">
          <h3 className="text-xl font-bold mb-4">{t("donorDetails")}</h3>
          <div className="space-y-2">
            <p>
              <strong>{t("id")}:</strong> {selectedDonor.id}
            </p>
            <p>
              <strong>{t("donor")}:</strong>{" "}
              {selectedDonor.donorName && selectedDonor.donorName.trim() !== ""
                ? selectedDonor.donorName
                : selectedDonor.anonymous === "1"
                ? t("anonymousDonor")
                : t("unknownDonor")}
            </p>
            <p>
              <strong>{t("amount")}:</strong> {selectedDonor.amount}
            </p>
            <p>
              <strong>{t("date")}:</strong> {selectedDonor.date}
            </p>
            <p>
              <strong>{t("forSoulElevation")}:</strong> {selectedDonor.forSoulElevation || "N/A"}
            </p>
            <p>
              <strong>{t("forHealing")}:</strong> {selectedDonor.forHealing || "N/A"}
            </p>
            <p>
              <strong>{t("forSalvation")}:</strong> {selectedDonor.forSalvation || "N/A"}
            </p>
            <p>
              <strong>{t("forSuccess")}:</strong> {selectedDonor.forSuccess || "N/A"}
            </p>
            <p>
              <strong>{t("notes")}:</strong> {selectedDonor.notes || "N/A"}
            </p>
            <p>
              <strong>{t("createdAt")}:</strong> {selectedDonor.created_at}
            </p>
          </div>
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          >
            &times;
          </button>
        </div>
      </div>
    )}
  </div>
);
};
;

export default ALLDonation;
