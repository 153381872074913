import axios from "axios";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft, FaPlus, FaSearch } from "react-icons/fa";
import Select from "react-select";
import BASE_URL from "../constant";
import { useReactToPrint } from "react-to-print";

import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import htmlToPlainText from "../htmltotext";
import { BsBack } from "react-icons/bs";
import InvoiceTemplate from "./invoice-template";
const ManualOrderCreation = ({ isAdmin, currency }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [user, setUser] = useState([]);
  const [fuser, setfUser] = useState([]);

  const [guestInfo, setGuestInfo] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shippingaddres: "",
  });

  const [shipping, setShipping] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [products, setProducts] = useState([]);

  const removeItem = (id) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== id));
  };
  const [savedLang, setSaveLang] = useState(currency);

  const [type, setType] = useState("user");

  const [searchQuery, setSearchQuery] = useState("");

  const [expandedProduct, setExpandedProduct] = useState(null);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        let url = `${BASE_URL}/api_book.php/pdfbooks`;

        const response = await axios.get(url);
        const bookData = response.data;
        setProducts(bookData);
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    };
    fetchFilterOptions();
    GetUsers();
  }, []);
  const GetUsers = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api_offline_orders.php?action=getAllUsers`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Users successfully fetched:", response.data);

      if (!Array.isArray(response.data)) {
        throw new Error("Invalid data format: Expected an array");
      }

      const fileOptions = response.data.map((u) => ({
        value: u.id, // You can change this to u.Name, u.Phone, or u.Email if needed
        label: `${u.Name} - ${u.Email} - ${u.Phone}`,
      }));

      setfUser(fileOptions);
      setUser(response.data);
    } catch (error) {
      console.error(
        "Error fetching users:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const printRef = useRef(null); // Initialize the ref

  const handlePrint = useReactToPrint({
    documentTitle: "Invoice",
    contentRef: printRef,
  });
  const items = products.filter((product) => {
    const matchesSearch =
    product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (product.asin && product.asin.toLowerCase().includes(searchQuery.toLowerCase()));
  

    return matchesSearch;
  });
  const [quantities, setQuantities] = useState({});
  const handleQuantityChange = (id, delta) => {
    setQuantities((prev) => ({
      ...prev,
      [id]: Math.max(1, (prev[id] || 1) + delta),
    }));
  };
  const totalPrice = selectedItems.reduce(
    (acc, item) =>
      acc +
      (type === "user"
        ? savedLang !== "ils"
          ? item.price
          : item.price_is
        : savedLang !== "ils"
        ? item.wholesale_price
        : item.wholesale_price_is) *
        item.quantity,
    0
  );
  const grandTotal =
    totalPrice +
    parseFloat(shipping) +
    parseFloat(taxes) -
    parseFloat(discount);

  const addItemToList = (item) => {
    const availableQuantity =
      savedLang !== "ils"
        ? item.warehouse_quantity
        : item.warehouse_quantity_is;

    const newQuantity = quantities[item.id] || 1;

    setSelectedItems((prevItems) => {
      // Check if the item already exists in the selected list
      const existingItem = prevItems.find(
        (selected) => selected.id === item.id
      );
      const selectedQuantity = existingItem ? existingItem.quantity : 0;
      const totalQuantity = selectedQuantity + newQuantity;

      if (availableQuantity > 0 && availableQuantity >= totalQuantity) {
        if (existingItem) {
          return prevItems.map((selected) =>
            selected.id === item.id
              ? { ...selected, quantity: totalQuantity }
              : selected
          );
        } else {
          return [...prevItems, { ...item, quantity: newQuantity }];
        }
      } else {
        alert("Not enough stock available");
        return prevItems; // Prevent state update if stock is insufficient
      }
    });

    setQuantities({});
  };

  const resetOrder = () => {
    setSelectedItems([]);
    setQuantities({});
    setGuestInfo({
      name: "",
      email: "",
      phone: "",
      address: "",
      shippingaddres: "",
    });
    setShipping(0);
    setDiscount(0);
    setTaxes(0);
  };
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const handleViewInvoice = (order) => {
    setSelectedInvoice(order);
    setShowInvoiceModal(true);
  };
  const confirmOrder = async () => {
    // Validation checks
    if (!guestInfo.name) {
      alert("Name is required");
      return;
    }
    if (!guestInfo.email) {
      alert("Email is required");
      return;
    }
    if (!guestInfo.phone) {
      alert("Phone number is required");
      return;
    }
    if (!guestInfo.address) {
      alert("Billing address is required");
      return;
    }
    if (!guestInfo.shippingaddres) {
      alert("Shipping address is required");
      return;
    }
    if (selectedItems.length === 0) {
      alert("At least one item must be selected");
      return;
    }

    // Prepare order details
    const details = selectedItems.map((item) => ({
      book_id: item.book_id ? `${item.book_id}-${item.id}` : item.id,

      quantity: item.quantity,
      price:
        type === "user"
          ? savedLang !== "ils"
            ? item.price
            : item.price_is
          : savedLang !== "ils"
          ? item.wholesale_price
          : item.wholesale_price_is,
      title: item.title,
    }));

    const orderData = {
      name: guestInfo.name,
      email: guestInfo.email,
      phone: guestInfo.phone,
      billing_address: guestInfo.address,
      shipping_address: guestInfo.shippingaddres,
      user_type: type,
      discount: discount,
      sub_total: totalPrice,
      final_amount: grandTotal,
      taxes: taxes,
      shipping: shipping,
      currency: savedLang,
      payment_mode: "Card",
      payment_type: "full",
      details: details,
    };

    console.log("Order Data:", orderData);
    try {
      const response = await axios.post(
        `${BASE_URL}/api_offline_orders.php`,
        orderData
      );

      console.log(response.data.order_id);
      handleViewInvoice({
        id: response.data.order_id,
        username: guestInfo.name,
        email: guestInfo.email,
        phone: guestInfo.phone,
        billing_address: guestInfo.address,
        shipping_address: guestInfo.shippingaddres,
        user_type: type,
        discount: discount,
        sub_total: totalPrice,
        final_amount: grandTotal,
        taxes: taxes,
        shipping_cost: shipping,

        currency: savedLang,
        payment_mode: "Card",
        payment_type: "full",
        details: details,
      });
      setSelectedItems([]);
      setQuantities({});
      setGuestInfo({
        name: "",
        email: "",
        phone: "",
        address: "",
        shippingaddres: "",
      });
      setShipping(0);
      setDiscount(0);
      setTaxes(0);
      //Getusers(); // Refresh user list
      alert("Order Created ");
    } catch (error) {
      console.error("Error updating user:", error);
      alert("An error occurred while Creating order. Please try again.");
    }
  };

  return (
    <div className="flex  mt-4   m-4 flex-col p-8">
      {/* User Info Section */}
      {showInvoiceModal && selectedInvoice && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-white rounded-lg shadow-lg p-6 w-[800px] max-h-90vh] "
            id="invoice-modal-content"
          >
            <div // Attach ref here
              className="  max-h-[80vh] overflow-y-auto"
            >
              <div ref={printRef}>
                <InvoiceTemplate order={selectedInvoice} />
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={handlePrint}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-800"
              >
                {t("print")}
              </button>
              <button
                onClick={() => {
                  setShowInvoiceModal(false);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-800"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="w-full mb-4 p-4 bg-gray-100 ">
        <h2 className="text-lg font-bold mb-2">{t("user_information")}</h2>

        <Select
          id="users"
          name="users"
          options={fuser} // Ensure fuser contains { value, label } pairs
          onChange={(selectedOption) => {
            const selectedUser = user.find(
              (u) =>
                u.id === selectedOption.value ||
                u.Name === selectedOption.value ||
                u.Phone === selectedOption.value ||
                u.Email === selectedOption.value
            );

            if (selectedUser) {
              setGuestInfo({
                name: selectedUser.Name || "",
                email: selectedUser.Email || "",
                phone: selectedUser.Phone || "",
                address: selectedUser.Billing_Address || "",
                shippingaddres: selectedUser.Shipping_Address || "",
              });
              setType(selectedUser.Type);
            }
          }}
          placeholder={
            // "Search"
            t("search")
          }
          className="w-full"
        />

        <div className="space-y-2">
          <h2 className="text-sm font-bold mb-2">{t("name")}</h2>
          <input
            type="text"
            placeholder={t("name")}
            className="w-full p-2 border rounded"
            value={guestInfo.name}
            onChange={(e) =>
              setGuestInfo({ ...guestInfo, name: e.target.value })
            }
          />
          <h2 className="text-sm font-bold mb-2">{t("email")}</h2>
          <input
            type="email"
            placeholder={t("email")}
            className="w-full p-2 border rounded"
            value={guestInfo.email}
            onChange={(e) =>
              setGuestInfo({ ...guestInfo, email: e.target.value })
            }
          />
          <h2 className="text-sm font-bold mb-2">{t("phone_no")}</h2>
          <input
            type="phone"
            placeholder={t("phone_no")}
            className="w-full p-2 border rounded"
            value={guestInfo.phone}
            onChange={(e) =>
              setGuestInfo({ ...guestInfo, phone: e.target.value })
            }
          />
          <h2 className="text-sm font-bold mb-2">{t("billing_address")}</h2>
          <input
            type="text"
            placeholder={t("billing_address")}
            className="w-full p-2 border rounded"
            value={guestInfo.address}
            onChange={(e) =>
              setGuestInfo({ ...guestInfo, address: e.target.value })
            }
          />
          <h2 className="text-sm font-bold mb-2">{t("shipping_address")}</h2>
          <input
            type="text"
            placeholder={t("shipping_address")}
            className="w-full p-2 border rounded"
            value={guestInfo.shippingaddres}
            onChange={(e) =>
              setGuestInfo({ ...guestInfo, shippingaddres: e.target.value })
            }
          />
          <div className="flex items-center gap-4">
            {isAdmin && (
              <div className="flex flex-col">
                <h2 className="text-sm font-bold mb-1">{t("currency")}</h2>
                <select
                  className="p-2 border rounded"
                  value={savedLang}
                  onChange={(e) => setSaveLang(e.target.value)}
                >
                  <option value="usd">{t("usd")}</option>
                  <option value="ils">{t("ils")}</option>
                </select>
              </div>
            )}
            <div className="flex flex-col">
              <h2 className="text-sm font-bold mb-1">{t("type")}</h2>
              <select
                className="p-2 border rounded"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="user">{t("user")}</option>
                <option value="distributer">{t("distributor")}</option>
              </select>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>

      <div className="flex w-full">
        {/* Available Items */}
        <div className="w-2/3 pr-4 ">
          <h2 className="text-lg font-bold mb-2">{t("available_books")}</h2>
          <div className="flex items-center justify-between mb-4  ">
            {/* Search Bar */}
            <div className="flex items-center border rounded-md px-3 py-2 w-1/3">
              <FaSearch className="text-gray-500 mr-2" />
              <input
                type="text"
                placeholder={
                  // "Search"
                  t("search_title")
                }
                className="w-full outline-none bg-transparent"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="bg-gray-100 p-4 flex items-center font-semibold border-b">
            <div className="w-20">
              {/* Image */}
              {t("image")}
            </div>{" "}
            {/* Placeholder for image */}
            {/* <div className="flex-1">{t("product-name")}</div> */}
            {/* <div className="w-32">{t("asin")}</div> */}
            <div className="flex-1 ml-4">
              <h3 className="text-lg font-semibold">
                {/* Title */}
                {t("title")}
              </h3>
              <p className="text-gray-500">{t("sku")}</p>
            </div>
            <div className="w-24">
              {/* Price */}
              {t("price")}
            </div>
            <div className="w-32">
              {/* Qty */}
              {t("qty")}
            </div>
            <div className="w-32 text-center">
              {/* Action */}
              {t("action")}
            </div>
          </div>
          <div className="h-[250px] overflow-auto">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              {items.map((product) => (
                <div key={product.id} className="border-b p-4 last:border-0">
                  <div className="flex items-center">
                    <img
                      src={product.images[0]}
                      alt={product.name}
                      className="w-10 h-10 object-cover mr-4"
                    />
                    <div className="flex-1">
                      <h3 className="text-md font-semibold">
                        {htmlToPlainText(product.title)}
                      </h3>
                      <p className="text-sm text-gray-500">{product.asin}</p>
                    </div>
                    <p className="text-sm text-gray-900 font-semibold w-24">
                      {savedLang !== "ils" ? "$" : "₪"}
                      {type === "user"
                        ? savedLang !== "ils"
                          ? product.price
                          : product.price_is
                        : savedLang !== "ils"
                        ? product.wholesale_price
                        : product.wholesale_price_is}
                    </p>
                    {product.volumes.length > 0 ? (
                      <button
                        onClick={() =>
                          setExpandedProduct(
                            expandedProduct === product.id ? null : product.id
                          )
                        }
                        className="ml-4 px-4 py-2 bg-gray-200 text-gray-700 rounded-md  text-md"
                      >
                        <span
                          style={{
                            color:
                              expandedProduct === product.id ? "green" : "red",
                          }}
                        >
                          {expandedProduct === product.id ? (
                            <FaChevronUp title={t("hide_options")} />
                          ) : (
                            <FaChevronDown title={t("view_options")} />
                          )}
                        </span>

                        {/* {expandedProduct === product.id
                        ? "↑"
                        : "↓"} */}
                      </button>
                    ) : (savedLang !== "ils"
                        ? product.warehouse_quantity
                        : product.warehouse_quantity_is) > 0 ? (
                      <>
                        <div className="flex items-center">
                          <button
                            onClick={() => handleQuantityChange(product.id, -1)}
                            className="px-3 py-1 bg-gray-200 rounded-l"
                          >
                            -
                          </button>
                          <span className="px-4 py-1 bg-gray-100">
                            {quantities[product.id] || 1}
                          </span>
                          <button
                            onClick={() => handleQuantityChange(product.id, 1)}
                            className="px-3 py-1 bg-gray-200 rounded-r"
                          >
                            +
                          </button>
                        </div>
                        <button
                          className="ml-2 px-3 py-1 bg-black text-white rounded-md  text-md"
                          onClick={(e) => {
                            addItemToList(product);
                            // purchase(e,product,[],savedLang !== "ils" ? product.price:product.price_is)
                          }}
                        >
                          +{t("add_to_cart")}
                        </button>
                      </>
                    ) : (
                      <button
                        // onClick={() =>
                        //   setExpandedProduct(
                        //     expandedProduct === product.id ? null : product.id
                        //   )
                        // }
                        className="ml-4 px-4 py-2 bg-red-200 text-gray-700 rounded-md  text-md"
                      >
                        {/* Out of Stock */}
                        {t("out_of_stock")}
                      </button>
                    )}
                  </div>
                  {expandedProduct === product.id && product.volumes && (
                    <div className="mt-2 bg-gray-100 rounded-md p-2">
                      {product.volumes.map((option) => (
                        <div
                          key={option.id}
                          className="flex items-center border-b p-2 last:border-0"
                        >
                          <img
                            src={product.images[0]}
                            alt={option.title}
                            className="w-10 h-10 object-cover mr-2"
                          />
                          <div className="flex-1">
                            <h4 className="text-md font-medium">
                              {htmlToPlainText(option.title)}
                            </h4>
                            <p className="text-sm text-gray-500">
                              {option.asin}
                            </p>
                          </div>
                          <p className=" text-sm text-gray-900 font-semibold w-20">
                            {savedLang !== "ils" ? "$" : "₪"}
                            {type === "user"
                              ? savedLang !== "ils"
                                ? option.price
                                : option.price_is
                              : savedLang !== "ils"
                              ? option.wholesale_price
                              : option.wholesale_price_is}
                          </p>
                          {(savedLang !== "ils"
                            ? option.warehouse_quantity
                            : option.warehouse_quantity_is) > 0 ? (
                            <>
                              <div className="flex items-center">
                                <button
                                  onClick={() =>
                                    handleQuantityChange(option.id, -1)
                                  }
                                  className="px-3 py-1 bg-gray-200 rounded-l"
                                >
                                  -
                                </button>
                                <span className="px-4 py-1 bg-gray-100">
                                  {quantities[option.id] || 1}
                                </span>
                                <button
                                  onClick={() =>
                                    handleQuantityChange(option.id, 1)
                                  }
                                  className="px-3 py-1 bg-gray-200 rounded-r"
                                >
                                  +
                                </button>
                              </div>
                              <button
                                className="ml-2 px-3 py-1 bg-black text-white rounded-md  text-md"
                                onClick={
                                  (e) => {
                                    addItemToList({
                                      ...option,
                                      images: product.images,
                                    });
                                  }
                                  // purchase(e,product,[option],savedLang !== "ils" ? product.price:product.price_is)
                                }
                              >
                                +{t("add_to_cart")}
                              </button>
                            </>
                          ) : (
                            <button className="ml-4 px-4 py-2 bg-red-200 text-gray-700 rounded-md text-md">
                              {/* Out Of Stock */}
                              {t("out_of_stock")}
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
              {items.length === 0 && (
                <div className="text-center p-6 text-gray-500">
                  {/* {t("no-products-found")} */}
                  {t("loading")}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Selected Items */}
        <div className="flex flex-col justify-center w-1/2 space-x-4">
          {/* Selected Books Section */}
          <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-2 ">
            <h2 className="text-lg font-bold mb-2">{t("selected_books")}</h2>
            {selectedItems.length === 0 ? (
              <p className="text-gray-500">{t("no_items_added_yet")}</p>
            ) : (
              <ul className=" h-[150px] overflow-auto">
                {selectedItems.map((item) => (
                  <li
                    key={item.id}
                    className="flex justify-between items-center border-b py-2"
                  >
                    {/* <img src={item.images[0]} alt={item.title} className="w-10 h-10 mr-2" />
                                <span>{htmlToPlainText(item.title)}</span>
                                <span className="text-gray-600">₪{item.price} x {item.quantity}</span> */}
                    <div className="flex items-center">
                      <img
                        src={item.images[0]}
                        alt={item.title}
                        className="w-10 h-10 mr-2"
                      />
                      <span>{htmlToPlainText(item.title)}</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="text-gray-600">
                        {/* ₪{item.price} x {item.quantity} */}
                        {savedLang !== "ils" ? "$" : "₪"}
                        {type === "user"
                          ? savedLang !== "ils"
                            ? item.price
                            : item.price_is
                          : savedLang !== "ils"
                          ? item.wholesale_price
                          : item.wholesale_price_is}
                        x {item.quantity}
                      </span>
                      <button
                        onClick={() => removeItem(item.id)}
                        className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600 transition"
                      >
                        {t("remove")}
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Order Summary Section */}
          <div className="bg-white p-4 rounded-lg shadow-lg mt-4">
            <h2 className="text-lg font-bold mb-2">{t("order_summary")}</h2>
            <div className="flex justify-between mb-2">
              <span>
                {t("subtotal")}:{/* Subtotal: */}
              </span>
              <span>
                {savedLang !== "ils" ? "$" : "₪"}
                {totalPrice.toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between mb-2">
              <span>
                {t("shipping")}:{/* Shipping: */}
              </span>
              <input
                type="number"
                value={shipping}
                onChange={(e) => setShipping(e.target.value)}
                className="border p-1 w-20 text-right"
              />
            </div>
            <div className="flex justify-between mb-2">
              <span>
                {t("discount")}:{/* Discount: */}
              </span>
              <input
                type="number"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                className="border p-1 w-20 text-right"
              />
            </div>
            <div className="flex justify-between mb-2">
              <span>
                {t("taxes")}
                {/*
                 */}
                :
              </span>
              <input
                type="number"
                value={taxes}
                onChange={(e) => setTaxes(e.target.value)}
                className="border p-1 w-20 text-right"
              />
            </div>
            <div className="flex justify-between font-bold text-lg mt-4">
              <span>{t("total")}:</span>
              <span>
                {savedLang !== "ils" ? "$" : "₪"}
                {grandTotal.toFixed(2)}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <button
          className="bg-red-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-red-600 transition mx-2"
          onClick={confirmOrder}
        >
          {t("confirm_order")}
        </button>
        <button
          className="bg-gray-800 text-white px-6 py-3 rounded-lg shadow-md hover:bg-gray-400 transition mx-2"
          onClick={resetOrder}
        >
          {t("create_new_order")}
        </button>
      </div>
    </div>
  );
};

const ManualOrder = ({ isAdmin, currency }) => {
  const { i18n } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isnewModalOpen, setnewModalOpen] = useState(false);
  const [trackingId, setTrackingId] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const handleViewInvoice = (order) => {
    setSelectedInvoice(order);
    setShowInvoiceModal(true);
  };
  const delivery_status_enum = (stat) => {
    switch (stat.toLowerCase()) {
      case "pending":
        return "Booked";
      case "shipped":
        return "Dispatched";
      case "delivered":
        return "Delivered";
      case "cancelled":
        return "Cancel";
      default:
        return "Booked";
    }
  };

  // Admin statuses back to database statuses
  const admin_status_enum = (adminStat) => {
    switch (adminStat.toLowerCase()) {
      case "booked":
        return "pending";
      case "dispatched":
        return "shipped";
      case "delivered":
        return "delivered";
      case "cancel":
        return "cancelled";
      default:
        return "pending"; // Default case
    }
  };
  const deliveryStatuses = ["Booked", "Dispatched", "Delivered", "Cancel"];

  const handleEdit = async (order) => {
    //   await fetchOrderDetails(order.id);
    setSelectedOrder(order);
    setModalOpen(true);
  };

  useEffect(() => {
    GetOrder();
  }, []);

  // const fetchOrderDetails = async (orderId) => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/api_offline_orders.php?action=get_order_details&order_id=${orderId}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     console.log('Order details fetched:', response.data);
  //     setSelectedOrder({ ...response.data.order, id: orderId });
  //     setDeliveryStatus(response.data.order.delivery_status);
  //     setTrackingId(response.data.order.tracking_id);
  //   } catch (error) {
  //     console.error('Error fetching order details:', error);
  //   }
  // };

  const GetOrder = async () => {
    try {
      const response = await axios.get(
        isAdmin
          ? `${BASE_URL}/api_offline_orders.php`
          : `${BASE_URL}/api_offline_orders.php?currency=${currency}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Orders successfully fetched:", response.data);
      setOrders(response.data.orders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const handleStatusChange = (e) => {
    setDeliveryStatus(e.target.value);
  };

  const handleTrackingIdChange = (e) => {
    setTrackingId(e.target.value);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
    setTrackingId(""); // Resetting state when modal closes
    setDeliveryStatus(""); // Resetting state when modal closes
  };

  const handleSaveTrackingId = async () => {
    try {
      const updatedOrder = {
        order_id: selectedOrder.id,
        tracking_id: trackingId,
        delivery_status: admin_status_enum(deliveryStatus),
      };
      console.log(updatedOrder);

      // const promises = [];

      if (selectedOrder.tracking_id !== trackingId) {
        const response_tracking = axios.put(
          `${BASE_URL}/api_porders.php?action=update_tracking_status`,
          {
            order_id: updatedOrder.order_id,
            tracking_id: updatedOrder.tracking_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response_tracking.data);
      }

      if (selectedOrder.delivery_status !== deliveryStatus) {
        const response_status = await axios.put(
          `${BASE_URL}/api_porders.php?action=update_tracking_status`,
          {
            order_id: updatedOrder.order_id,
            delivery_status: updatedOrder.delivery_status,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response_status.data);
      }

      // await Promise.all(promises);
      console.log("Updates successful");

      const updatedOrders = orders.map((order) =>
        order.id === updatedOrder.order_id
          ? {
              ...order,
              tracking_id: updatedOrder.tracking_id,
              delivery_status: updatedOrder.delivery_status,
            }
          : order
      );

      setOrders(updatedOrders);
      setModalOpen(false); // Close modal after saving
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };
  const printRef = useRef(null); // Initialize the ref

  const handlePrint = useReactToPrint({
    documentTitle: "Invoice",
    contentRef: printRef,
  });
  return isnewModalOpen ? (
    // <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
    <div className="bg-white p-6 rounded-lg shadow-lg w-[100%] h-[70%] overflow-auto ">
      <div className="flex  mb-4">
        <button
          onClick={() => {
            setnewModalOpen(false);
          }}
          className="bg-gray-600 drop-shadow-xl hover:bg-black text-white px-4 py-2 rounded-md flex items-center focus:outline-none mb-4 mr-8"
        >
          {/* <BsBack className="mr-2" /> */}
          <FaChevronLeft />
        </button>
        <h1 className="text-2xl font-bold mb-4">Offline Order Form</h1>
      </div>
      <ManualOrderCreation isAdmin={isAdmin} currency={currency} />
      {/* < */}
      {/* /div>  */}
    </div>
  ) : (
    <div className="p-6">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold mb-4">{t("offline_orders")}</h1>
        <button
          onClick={() => {
            setnewModalOpen(true);
          }}
          className="bg-[#6D3815] drop-shadow-xl hover:bg-black text-white px-4 py-2 rounded-md flex items-center focus:outline-none"
        >
          <FaPlus className="mr-2" />
          {t("add_new_order")}
        </button>
      </div>
      <table className="border-collapse w-full border border-gray-200 mt-4">
        <thead className="bg-[#fde5c3c4] text-left">
          <tr>
            <th className="border border-gray-300 p-2">{t("order_id")}</th>
            <th className="border border-gray-300 p-2">
              {t("customer_name")},
            </th>
            <th className="border border-gray-300 p-2">{t("currency")}</th>
            <th className="border border-gray-300 p-2">{t("total_price")}</th>
            <th className="border border-gray-300 p-2">
              {t("shipment_status")}
            </th>
            {/* <th className="border border-gray-300 p-2">Tracking ID</th> */}
            <th className="border border-gray-300 p-2">{t("action")}</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td className="border border-gray-300 p-2">OFL-ORD-{order.id}</td>
              <td className="border border-gray-300 p-2">{order.username}</td>
              <td className="border border-gray-300 p-2">
                {t(order.currency)}
              </td>
              <td className="border border-gray-300 p-2">
                {order.currency !== "ils" ? "$" : "₪"} {order.final_amount}
              </td>
              <td className="border border-gray-300 p-2">
                {t(order.delivery_status)}
              </td>
              {/* <td className="border border-gray-300 p-2">{order.tracking_id}</td> */}
              <td className="border border-gray-300 p-2">
                <button
                  onClick={() => handleEdit(order)}
                  className="bg-gray-800 hover:bg-gray-600 w-full text-white rounded px-2 py-1 transition-all duration-200"
                >
                  {t("view_order")}
                </button>
                <button
                  onClick={() => handleViewInvoice(order)}
                  className="bg-[#6D3815] hover:bg-[#5a2f10] w-full text-white rounded px-2 py-1 mt-2 transition-all duration-200"
                >
                  {t("view_invoice")}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedOrder && (
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
          dir={i18n.dir()}
        >
          <div className="bg-white p-8 rounded-lg shadow-lg w-[80%] max-w-4xl border border-gray-300">
            <div className="text-center mb-6">
              {/* <h2 className="text-2xl font-bold">Basic-INVOICE:</h2> */}
              <p className="text-gray-600">
                {t("order_id")}: OFL-ORD-{selectedOrder.id}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-semibold">{t("type")}:</h3>
              <p className="text-gray-600">
                {t(selectedOrder.user_type == "user" ? "user" : "distributor")}
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4 border-b pb-4 mb-4">
              <div>
                <h3 className="text-lg font-semibold">{t("bill_to")}</h3>
                <p className="text-gray-600">{selectedOrder.username}</p>
                <p className="text-gray-600">{selectedOrder.Billing_Address}</p>
                <p className="text-gray-600">{selectedOrder.Phone}</p>
              </div>
              <div className="text-right">
                <h3 className="text-lg font-semibold">{t("ship_to")}:</h3>
                <p className="text-gray-600">
                  {selectedOrder.Shipping_Address}
                </p>
              </div>
            </div>

            <table className="w-full border-collapse border border-gray-300 mb-4">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 p-2">{t("sku")}</th>
                  <th className="border border-gray-300 p-2">{t("item")}</th>
                  <th className="border border-gray-300 p-2">{t("price")}</th>
                  <th className="border border-gray-300 p-2">{t("qty")}</th>
                  <th className="border border-gray-300 p-2">{t("total")}</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrder.details?.map((item) => (
                  <tr key={item.id} className="text-center">
                    <td className="border border-gray-300 p-2">{item.SKU}</td>
                    <td className="border border-gray-300 p-2">
                      {htmlToPlainText(item.title)}
                    </td>
                    <td className="border border-gray-300 p-2" dir="ltr">
                      {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                      {item.price}
                    </td>
                    <td className="border border-gray-300 p-2">
                      {item.quantity}
                    </td>
                    <td className="border border-gray-300 p-2" dir="ltr">
                      {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                      {(item.price * item.quantity).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-gray-600">
                  {t("payment_method")}:{" "}
                  {selectedOrder.paymentMethod ?? "Card Payment"}
                </p>
                <p className="text-gray-600">
                  {t("payment_type")}: {selectedOrder.Payment_Type ?? "Full"}
                </p>
                <p className="text-gray-600">
                  {t("order_date")}: {selectedOrder.orderDate}
                </p>
              </div>
              <div className="text-right">
                <p className="text-gray-600" dir="ltr">
                  {t("discount")}:{" "}
                  {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                  {selectedOrder.discount}
                </p>
                <p className="text-gray-600" dir="ltr">
                  {t("taxes")}: {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                  {selectedOrder.taxes}
                </p>
                <h3 className="text-xl font-bold" dir="ltr">
                  {t("total")} {selectedOrder.currency !== "ils" ? "$" : "₪"}{" "}
                  {selectedOrder.final_amount}
                </h3>
              </div>
            </div>

            <div className="text-center mt-6">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white px-6 py-2 rounded"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}
      {showInvoiceModal && selectedInvoice && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          dir={i18n.dir()}
        >
          <div
            className="bg-white rounded-lg shadow-lg p-6 w-[800px] max-h-90vh] "
            id="invoice-modal-content"
          >
            <div // Attach ref here
              className="  max-h-[80vh] overflow-y-auto"
            >
              <div ref={printRef}>
                <InvoiceTemplate order={selectedInvoice} />
              </div>
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <button
                onClick={handlePrint}
                className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-800"
              >
                {t("print")}
              </button>
              <button
                onClick={() => {
                  setShowInvoiceModal(false);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-800"
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ManualOrder;
