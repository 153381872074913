import React, { useState, useEffect } from "react";
import axios from "axios";
import BASE_URL from "../constant";
import LoadingPopup from "../Pages/LoadingPopup";

import { FaFilePdf, FaUpload } from "react-icons/fa"; // Import React Icons
import { t } from "i18next";

const UploadPDF = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchPdfs();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const fetchPdfs = async () => {
    try {
      let url = `${BASE_URL}/add_files.php`;
      const response = await axios.get(url);
      const bookData = response.data.results;
      console.log(response.data.results);
      if (Array.isArray(bookData)) {
        setData(bookData);
      } else {
        console.error("Expected an array of pdfs");
      }
    } catch (error) {
      console.error("Error fetching pdfs:", error);
    }
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [files, setFiles] = useState([]);

  // Open modal
  const openModal = () => setModalOpen(true);

  // Close modal
  const closeModal = () => {
    setModalOpen(false);
    setFiles([]); // Clear files when closing modal
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files).slice(0, 15); // Limit to 15 files
    setFiles(selectedFiles);
  };
  const logFormData = (formData) => {
    for (let pair of formData.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
  };
  // Handle file upload
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('name', "file");
    files.forEach((file) => {
      formData.append('pdfs[]', file);
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    logFormData(formData);
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BASE_URL}/add_files.php`,
        formData,
        config
      );
      logFormData(formData);
      console.log(response);
      if (response.status === 200) {
        const newFiles = files.map((file, index) => ({
          id: data.length + index + 1,
          filename: file.name,
          filepath: `uploads/non-searchable/${file.name}.pdf`, // Correct file path
        }));
        setData([...data, ...newFiles]);
        fetchPdfs();
        setIsLoading(false);
        closeModal();
      }
    } catch (error) {
      console.error("Error adding files :", error);
      setIsLoading(false);
      closeModal();
    } 
  };
  
  

  return (
    <div className="container mx-auto p-4">
 <button
        onClick={openModal}
        className="mb-4 px-4 py-2 bg-[#6D3815] text-white rounded hover:bg-black flex items-center"
      >
        <FaUpload className="mr-2" /> {t("upload_pdfs")}
      </button>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-[#fde5c3c4] text-left">
            <tr className="w-full border-b">
              <th className="py-2 px-4 text-left text-gray-600">{t("id")}</th>
              <th className="py-2 px-4 text-left text-gray-600">{t("name")}</th>
              <th className="py-2 px-4 text-left text-gray-600">{t("path")}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="py-2 px-4 text-gray-700">{item.id}</td>
                <td className="py-2 px-4 text-gray-700">{item.filename}</td>
                <td className="py-2 px-4 text-gray-700 flex items-center">
                  <a
                    href={item.filepath}
                    className="flex items-center text-blue-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFilePdf className="mr-2" /> {item.filepath}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full relative">
            <button
              onClick={closeModal}
              className="absolute text-xl top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              &times;
            </button>
            <h2 className="text-xl mb-4">
              {t("upload_pdfs")}{" "}
              <span className="text-sm">({t("select_15_pdfs")})</span>
            </h2>

            <input
              type="file"
              accept=".pdf"
              multiple
              onChange={handleFileChange}
              className="mb-4"
            />
            {files.length > 0 && (
              <div className="mb-4">
                <h3 className="text-lg mb-2">{t("selected_files")}:</h3>
                <ul>
                  {files.map((file, index) => (
                    <li key={index} className="flex items-center mb-2">
                      <FaFilePdf className="mr-2 text-red-500" />
                      {file.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <button
              onClick={handleUpload}
              className="px-4 py-2 bg-[#6D3815] text-white rounded hover:bg-black"
            >
              {t("upload")}
            </button>
            <button
              onClick={closeModal}
              className="ml-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
            >
              {t("cancel")}
            </button>
          </div>
        </div>
      )}

      {/* Loading Popup */}
      {isLoading && <LoadingPopup />}
    </div>
    
  );
};

export default UploadPDF;
